import React, { Dispatch, useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton, CDataTable } from "@coreui/react";
import { FaGlobe, FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import AddTagModal from "components/modals/tags/AddTagModal";
import EditTagModal from "components/modals/tags/EditTagModal";
import DeleteTagModal from "components/modals/tags/DeleteTagModal";
import TagActions from "./TagActions";
import TagTableVariables from "./tagTableVariables";
import tagApi from "services/apis/tag.api";
import { ApplicationState } from "store";
import calculatePageNumber from "helper/calculatePageNumber";
import { openDeleteTagModal, openEditTagModal } from "store/tag/action";
import useDebounce from "hooks/useDebounce";



const Tags = () => {
    const [addTagModalOpen, setAddTagModalOpen] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [searchKey, setSearchKey] = useState("");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const dispatch: Dispatch<any> = useDispatch();
    const { alltag, isLoading } = useSelector(
        (state: ApplicationState) => state.tag
    );

    const editTagModal = useSelector(
        (state: ApplicationState) => state.tag.editTagModal
    );
    const deleteTagModal = useSelector(
        (state: ApplicationState) => state.tag.deleteTagModal
    );

    const loadTagData = useCallback((search: string) => {
        tagApi.fetchAllGlobalTag(dispatch, pageNo, true, search);
    }, [dispatch, pageNo]);

    const debouncedLoadTagData = useDebounce(loadTagData, 300);

    useEffect(() => {
        debouncedLoadTagData(searchKey);
    }, [debouncedLoadTagData, searchKey, pageNo]);

    useEffect(() => {
        if (alltag.totalcount) {
            const number = calculatePageNumber(alltag.totalcount);
            setTotalPage(number);
        }
    }, [alltag]);

    // Effect to restore focus after render
    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    });

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
    };

    const clearSearch = () => {
        setSearchKey("");
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const TagPageTitleBuild = () => {
        return (
            <>
                <div className="title">
                    <div className="page-title">Tags</div>
                    <div>
                        <button
                            className="create"
                            onClick={() => setAddTagModalOpen(true)}
                        >
                            Add New
                        </button>
                    </div>
                </div>
            </>
        );
    };

    const TagTableBuild = () => {
        return (
            <div className="tmf-table_wrapper">
                <div className="tmf-datatable__wrapper tmf_hide_build_in_pagination" style={{
                    width: "100%",
                    maxWidth: "100%",
                    overflowX: "auto"
                }}>
                    <div style={{ width: "1200px", minWidth: "100%", overflowX: "auto" }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            padding: "0.75rem 1.25rem",
                            backgroundColor: "#fff",
                        }}>
                            <div className="table-filter-section">
                                <span className="search">
                                    <CButton size="sm">
                                        <FaSearch style={{ height: "16px", width: "16px" }} />
                                    </CButton>
                                    <input
                                        ref={searchInputRef}
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Search By Tag Name"
                                        onChange={handleSearchInputChange}
                                        value={searchKey}
                                    />
                                    {searchKey && (
                                        <IoMdClose
                                            onClick={clearSearch}
                                            style={{
                                                cursor: "pointer",
                                                height: "20px",
                                                width: "20px",
                                            }}
                                        />
                                    )}
                                </span>
                            </div>

                            <span style={{ marginLeft: "auto" }}>
                                <DTablePagination
                                    tPage={totalPage}
                                    cPage={pageNo}
                                    onPageChange={(e) => setPageNo(e)}
                                    name="tags"
                                />
                            </span>
                        </div>

                        <CDataTable
                            items={alltag.data}
                            fields={TagTableVariables.fields}
                            responsive={true}
                            loading={isLoading}
                            noItemsViewSlot={<DataTableNoContent />}
                            hover
                            scopedSlots={{
                                name: (item) => (
                                    <td style={{ paddingLeft: "30px" }}>
                                        <div className="name">
                                            {item.isDeleted ? (
                                                <span style={{
                                                    fontSize: "1rem",
                                                    fontWeight: "500",
                                                    color: "#524467",
                                                    textDecoration: "line-through"
                                                }}>
                                                    {item.name ? item.name.charAt(0).toLowerCase() +
                                                        item.name.slice(1).toLowerCase() : "N/A"}
                                                </span>
                                            ) : (
                                                <span style={{
                                                    fontSize: "1rem",
                                                    fontWeight: "500",
                                                    color: "var(--txt-primary, #23005B)",
                                                }}>
                                                    {item.name ? item.name.charAt(0).toLowerCase() +
                                                        item.name.slice(1).toLowerCase() : "N/A"}
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                ),
                                description: (item) => (
                                    <td>
                                        <div className="detail">
                                            <span style={{ color: "var(--txt-primary, #23005B)" }}>
                                                {item.description ? item.description : "..."}
                                            </span>
                                        </div>
                                    </td>
                                ),
                                access: (item) => (
                                    <td>
                                        <div style={{
                                            fontSize: "1.4rem",
                                            marginLeft: "10px"
                                        }}>
                                            {
                                                item.isGlobal && (
                                                    <FaGlobe style={{
                                                        color: "var(--txt-primary, #23005B)"
                                                    }} />
                                                )
                                            }

                                        </div>
                                    </td>
                                ),
                                Action: (item) => (
                                    <td style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <TagActions tag={item} />
                                    </td>
                                ),
                            }}
                        />

                        <DTablePagination
                            tPage={totalPage}
                            cPage={pageNo}
                            onPageChange={(e) => setPageNo(e)}
                            name="tags"
                        />

                    </div>
                </div>
            </div>
        );
    };

    const TagActionsModal = () => {
        return (
            <>
                {addTagModalOpen && (
                    <AddTagModal
                        isOpen={true}
                        onClose={() => setAddTagModalOpen(false)}
                        refreshDetails={() => loadTagData(searchKey)}
                        title='Create New Global Tag'
                    />
                )}
                {editTagModal?.showModal && (
                    <EditTagModal
                        isOpen={editTagModal.showModal}
                        onClose={() => dispatch(openEditTagModal({ showModal: false }))}
                        refreshDetails={() => loadTagData(searchKey)}
                        tag={editTagModal.tag}
                        title='Edit Tag'
                    />
                )}
                {deleteTagModal?.showModal && (
                    <DeleteTagModal
                        isOpen={deleteTagModal.showModal}
                        onClose={() => dispatch(openDeleteTagModal({ showModal: false }))}
                        refreshDetails={() => loadTagData(searchKey)}
                        selectedNodeId={deleteTagModal.tag?.tagID!}
                        title='Do you want to delete this Tag?'
                    />
                )}
            </>
        );
    };

    return (
        <div>
            <TagPageTitleBuild />
            <TagTableBuild />
            <TagActionsModal />
        </div>
    );
};

export default Tags;