
import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import  { useState } from "react";
import Constants from "../../../constants/Constants";
import { Bounce, toast } from "react-toastify";

const UndoDeleteSubscriptionModal = ({ isOpen, onClose, id, refreshDetails,organizationId }) => {
  const [isSpinning, setSpinning] = useState(false);

  const deleteSubscription = async () => {
    setSpinning(true);
    await api
      .post(`${Constants.UNDO_DELETE_SUBSCRIPTION_URI}/${id}/${organizationId}`)
      .then(async (res) => {
        setSpinning(false);
        await onClose();
        toast.success("Subscription Undo Delete successfully!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        refreshDetails();
      }).catch((err) => {
        if(err.response){
          toast.error(`${err.response}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }else{
          toast.error("Something went wrong", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }).finally(()=>{
        setSpinning(false);
      });
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        {/* <CModalHeader>
          <CModalTitle>
            <Text fontSize="xl"> Delete Subscription</Text>
          </CModalTitle>
        </CModalHeader> */}
        <CModalBody>
          Do you want to undo delete this Subscription?
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton
                  style={{
                    background:"var(--primary)",
                    color:"white"
                  }}
                onClick={() => deleteSubscription()}
                size="sm"
              >
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="danger"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="primary" disabled size="sm">
               Deleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default UndoDeleteSubscriptionModal;
