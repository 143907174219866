interface IInputProps extends React.ComponentProps<"input"> {
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Input = (props: IInputProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.type === "number") {
      const invalidKeys = ["e", "E", "+", "-"];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
    // Call the user's onKeyDown if provided
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };
  return (
    <input
      className="input"
      autoComplete="off"
      autoCorrect="off"
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};

export default Input;
