import { CButton } from "@coreui/react";
import AvishButton from "components/button/button";
import Loader from "components/loader/Loader";
import { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "styles/_modal.scss";

interface IModalProps extends React.PropsWithChildren<any> {
  subtitle?: string;
  isLoading?: boolean;
  title: string;
  icon?: ReactNode;
  submitButtonValue?: string;
  submitButtonType?: "button" | "submit" | "reset" | undefined;
  closeOnBackdropClick?: boolean;
  onClose?: () => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  footerLeftContent?: ReactNode;
  footerActionContent?: ReactNode;
  headerContent?: ReactNode;
  showModal: boolean;
  isFormSubmitting?: boolean;
  isModalForm?: boolean;
  portalClass?: string;
  closeModal?: boolean;
  isSubmitView?:boolean
}

export default function AvishModal({
  title,
  icon,
  isLoading = false,
  subtitle = "",
  children,
  submitButtonValue = "Ok",
  submitButtonType = "button",
  closeOnBackdropClick = true,
  onClose = () => {},
  onSubmit = () => {},
  footerLeftContent,
  footerActionContent,
  className,
  showModal = false,
  isFormSubmitting = false,
  isModalForm = true,
  portalClass = "avish-modal",
  closeModal = false,
  isSubmitView=true,
  headerContent,
}: IModalProps) {
  const protalDiv = document.getElementById(portalClass);
  const [showModalChecker, setShowModalChecker] = useState(showModal);

  useEffect(() => {
    if (closeModal) closeModalMethod();
  }, [closeModal]);

  function modalCloseHandler(e) {
    e.preventDefault();
    closeModalMethod();
  }

  function closeModalMethod() {
    if (!closeOnBackdropClick || isLoading || isFormSubmitting) return;
    setShowModalChecker(false);
    var delay = 0;
    if (showModal) delay = 250;
    setTimeout(() => {
      onClose();
    }, delay);
  }

  function modalSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isLoading || isFormSubmitting) return;
    onSubmit(e);
  }

  const ModalContentBuild = () => {
    return isLoading ? (
      <Loader />
    ) : (
      <>
        <div className="avish-modal__header">
          <div className="avish-modal__header__title">
            {icon && (
              <div className="avish-modal__header__title__icon__wrapper">
                {icon}
              </div>
            )}

            <div className="avish-modal__header__title__text__wrapper">
              <div className="avish-modal__header__title__text">{title}</div>
              {subtitle.length > 0 && (
                <div className="avish-modal__header__title__subtitle">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                  dicta illo quo!
                </div>
              )}
            </div>
          </div>
          <div
            className="avish-modal__header__close"
            onClick={modalCloseHandler}
          >
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          {headerContent}
        </div>
        <div className="avish-modal__body">{children}</div>
        <div className="avish-modal__footer">
          <div className="avish-modal__footer__content">
            {footerLeftContent}
          </div>
          <div className="avish-modal__footer__action">
            {footerActionContent || (
              <>
                {/* <AvishButton
                  value="Cancel"
                  type="button"
                  onClick={modalCloseHandler}
                ></AvishButton> */}
                  {
                  isSubmitView && (
                    <AvishButton
                    value={submitButtonValue}
                    className="primary"
                    type={submitButtonType}
                    isButtonLoading={isFormSubmitting}
                    isButtonDisabled={isFormSubmitting}
                  ></AvishButton>
                  )
                }
                <CButton
                  color="danger"
                  onClick={modalCloseHandler}
                  size="sm"
                  style={{
                    borderRadius: "0.3rem",
                    fontSize: "0.8rem",
                    padding: "0.17em 0.7em",
                  }}
                >
                  Cancel
                </CButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return protalDiv
    ? ReactDOM.createPortal(
        
          <div
            role="dialog"
            className={[
              "avish-modal",
              isLoading ? "loading" : "",
              className,
              showModalChecker ? "" : "hide-modal",
            ].join(" ")}
          >
            <div
              className="avish-modal__backdropg"
              onClick={modalCloseHandler}
            ></div>
            {isModalForm ? (
              <form
                className="avish-modal__content"
                onSubmit={modalSubmitHandler}
              >
                <ModalContentBuild></ModalContentBuild>
              </form>
            ) : (
              <div className="avish-modal__content">
                <ModalContentBuild></ModalContentBuild>
              </div>
            )}
          </div>
        ,
        protalDiv
      )
    : null;
}
