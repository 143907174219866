import AvishModal from "components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import planApi from "services/apis/plan.api";
import { ApplicationState } from "store";
import { resetEditPlanModal, setEditPlanModal } from "store/plan/action";
import PlanFormElements from "./planFormElement";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";


export interface formStateProps {
  name: string;
  minimumLicenceCount: string;
  perLicenceCost: string;
  allowedNumberOfBookmarks: string;
  skuid: string;
  planType: string;
  subscriptionTypeID: string;
}

export const planType = [
  { label: "Legal", value: "legal" },
  { label: "Non-Legal", value: "non-legal" },
];


const EditPlanModal = ({ isOpen }) => {

  const [checkPlanExistBySkUid, setCheckPlanExistBySkUid] = useState(false);
  const [isCheckingBySkUidLoading, setIsCheckingBySkUidLoading] = useState(false);
  const [checkPlanExistByName, setCheckPlanExistByName] = useState(false);
  const [isPlanCheckingByNameLoading, setIsPlanCheckingByNameLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [formState, setFormState] = useState<formStateProps>({
    name: "",
    minimumLicenceCount: "",
    perLicenceCost: "",
    allowedNumberOfBookmarks: "",
    skuid: "",
    planType: "non-legal",
    subscriptionTypeID: "1"
  });

  const dispatch: Dispatch<any> = useDispatch();
  const editPlanModal = useSelector(
    (state: ApplicationState) => state.plan?.editPlanModal
  );
  const subscriptionTypes = useSelector(
    (state: ApplicationState) => state.subscription?.subscriptionTypes
  );


  const mappedSubscriptionTypes = subscriptionTypes.map((el) => ({
    label: el.type,
    value: el.id.toString(),
  }));


  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<ISelectData>();

  const [selectedPlanType, setSelectedPlanType] = useState<ISelectData>();


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const checkPlanNameExistByName = async (keyword: string) => {
    if (keyword.trim() !== '') {
      if (keyword.toLowerCase() != editPlanModal?.plan?.name.toLowerCase()) {
        setIsPlanCheckingByNameLoading(true)
        try {
          const res = await planApi.checkPlanExistByName(keyword);
          setCheckPlanExistByName(res);


        } catch {
          setCheckPlanExistByName(false);
        } finally {
          setIsPlanCheckingByNameLoading(false)
        }
      } else {
        setCheckPlanExistByName(false);
      }
    } else {
      setCheckPlanExistByName(false);
    }
  }

  const checkPlanExistBySKuid = async (keyword: string) => {
    if (keyword.trim() !== '') {
      if (keyword.toLowerCase() != editPlanModal?.plan?.skuid.toLowerCase()) {
        setIsCheckingBySkUidLoading(true)
        try {
          const res = await planApi.checkPlanBySkuid(keyword);
          setCheckPlanExistBySkUid(res);
        } catch {
          setCheckPlanExistBySkUid(false);
        } finally {
          setIsCheckingBySkUidLoading(false)
        }
      } else {
        setCheckPlanExistBySkUid(false);
      }


    } else {
      setCheckPlanExistBySkUid(false);
    }
  }


  function closeHandler() {
    setCheckPlanExistByName(false)
    setCheckPlanExistBySkUid(false)
    dispatch(setEditPlanModal({ showModal: false }));
  }

  function submitHandler(data) {

    setIsSpinning(true)
    planApi
      .updatePlan(dispatch, data, editPlanModal?.plan?.id)
      .then(() => {
        toast.success("😊 Plan edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while editing plan!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }).finally(() => {
        setIsSpinning(false);
      });
  }


  useEffect(() => {
    if (editPlanModal?.plan) {
      setFormState({
        name: editPlanModal?.plan?.name,
        minimumLicenceCount: editPlanModal?.plan?.minimumLicenceCount.toString(),
        perLicenceCost: editPlanModal?.plan?.perLicenceCost.toString(),
        allowedNumberOfBookmarks: editPlanModal?.plan?.allowedNumberOfBookmarks.toString(),
        skuid: editPlanModal?.plan?.skuid,
        planType: editPlanModal?.plan?.planType?editPlanModal?.plan?.planType:"non-legal",
        subscriptionTypeID: editPlanModal?.plan?.subscriptionTypeId.toString()
      });
      setSelectedSubscriptionType(() => {
        const defaultType = mappedSubscriptionTypes.find(
          (el) => el.value === editPlanModal?.plan?.subscriptionTypeId.toString()
        );
        return defaultType || mappedSubscriptionTypes[0];
      })
      setSelectedPlanType(
        editPlanModal?.plan?.planType
          ? editPlanModal.plan.planType.toString().toLowerCase() === "legal"
            ? planType[0]
            : planType[1]
          :   planType[1]
      );

    }
  }, [editPlanModal])

  useEffect(() => {
    const areFieldsEmpty = Object.values(formState).some((value) => String(value || "").trim() === "");
    if (checkPlanExistBySkUid || checkPlanExistByName || areFieldsEmpty) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [checkPlanExistByName, checkPlanExistBySkUid, formState]);


  return editPlanModal?.showModal ? (
    <div>
      {subscriptionTypes && (
        <CModal size="lg" className={[
          "avish-modal",
        ].join(" ")} show={isOpen} onClose={() => closeHandler()} closeOnBackdrop={true}>
          <CModalHeader closeButton>
            <CModalTitle>
              Edit Plan
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <PlanFormElements
              checkPlanExistBySkUid={checkPlanExistBySkUid}
              isCheckingBySkUidLoading={isCheckingBySkUidLoading}
              checkPlanExistByName={checkPlanExistByName}
              isPlanCheckingByNameLoading={isPlanCheckingByNameLoading}
              checkPlanNameExistByName={checkPlanNameExistByName}
              checkPlanExistBySKuid={checkPlanExistBySKuid}
              onChange={handleInputChange}
              formState={formState}
              setFormState={setFormState}
              setSelectedSubscriptionType={setSelectedSubscriptionType}
              selectedSubscriptionType={selectedSubscriptionType}
              setSelectedPlanType={setSelectedPlanType}
              selectedPlanType={selectedPlanType}
              mappedSubscriptionTypes={mappedSubscriptionTypes}
            ></PlanFormElements>
            {!isSpinning && (
              <div
                style={{
                  placeItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "10px",
                  float: "right",
                }}
              >
                <CButton
                  style={{
                    background: "var(--primary)",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer"

                  }}
                  type="submit"
                  size="sm"
                  onClick={() => submitHandler(formState)}
                  disabled={isButtonDisabled}
                >
                  Edit
                </CButton>

                <div className="ml-1">
                  <CButton color="danger"

                    onClick={() => closeHandler()} size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}

            {isSpinning && (
              <div
                style={{
                  placeItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "10px",
                  float: "right",
                }}
              >
                <CButton
                  disabled
                  style={{ background: "var(--primary)", color: "white" }}
                  size="sm"
                >
                  <i className="fa fa-spinner fa-spin"></i> Editing
                </CButton>
                <div className="ml-1">
                  <CButton color="danger" onClick={() => closeHandler()} size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}


          </CModalBody >
        </CModal>
      )
      }
    </div>
  ) : (
    <>
    </>
  )
};

export default EditPlanModal;
