import { DTablePagination } from "components/datatable/pagination";
import commonConstants from "constants/commonConstants";
import { useEffect, useRef, useState } from "react";


import ActivityFilter from "./ActivityFilter";
import ActivityTable from "./ActivityTable";
import { FaSignInAlt } from "react-icons/fa";
import api from "services/api.service";
import Constants from "constants/Constants";
import { Bounce, toast } from "react-toastify";
import organizationApi from "services/apis/organization.api";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";


const Activity = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const isFirstMount = useRef(true);
    const [activities, setActivities] = useState<IActivityModel>({
        firmID: null,
        startDate: null,
        endDate: null,
        activityBy: null,
        appType: null,
        activityType: null,
        source: null,
        userTypeId: null,
        isActive: null,
        ActivityStatus:null
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [activityData, setActivityData] = useState<IUserActivity[]>([]);
    const [pageNo, setPageNo] = useState(0);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [totalLoginCount, setTotalLoginCount] = useState<number>(0);
    const [activityTotalCount, setActivityTotalCount] = useState<number>(0);
    const [inactiveActivityCount, setInactiveActivityCount] = useState<number>(0);
    const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleTimeString());
    const [shouldLoadData, setShouldLoadData] = useState(false);

    const getOrganizationList = () => organizationApi.fetchAllOrganizations(dispatch);


    const GetAllActivity = async () => {
        setIsLoading(true);
        try {
            await api
                .post(`${Constants.GET_ALL_USER_ACTIVITY}/${pageNo}/${commonConstants.PAGE_SIZE}`, activities)
                .then(async (res) => {
                  
                    setActivityData(res.data.response.data);
                    const newTotalpage = Math.ceil(res.data.totalcount / commonConstants.PAGE_SIZE)
                    setTotalPage(newTotalpage);
                })
                .catch((err) => {
                    setIsError(true);
                    toast.error(`😔 Something Went Wrong`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } catch (e) {
            setIsLoading(false);
            setIsError(true);
            toast.error(`😔 Something Went Wrong`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    const GetTodayLoginCount = async () => {
        try {
            await api.get(`${Constants.GET_TODAY_LOGIN_COUNT}`)
                .then(async (res) => {

                    setInactiveActivityCount(res.data.totalInactiveActivityCount)
                    setTotalLoginCount(res.data.totalLoginCount)
                    setActivityTotalCount(res.data.activitytotalCount)
                })
                .catch((err) => {
                    toast.error(`😔 Something Went Wrong`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                });
        } catch (e) {
            toast.error(`😔 Something Went Wrong`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    //handle filter Data
    const loadFilterData = () => {
        setPageNo(0);
        GetAllActivity();
    };

    useEffect(() => {
        if (shouldLoadData) {
            loadFilterData();
            setShouldLoadData(false);
        }
    }, [shouldLoadData]);


    useEffect(() => {
        GetAllActivity()
    }, [pageNo]);

    useEffect(() => {

        GetTodayLoginCount();

        const intervalId = setInterval(GetTodayLoginCount, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (isFirstMount.current) {
            isFirstMount.current = false;
            getOrganizationList();
        }
    }, []);

    useEffect(() => {
        if (totalLoginCount) {
            setLastUpdated(new Date().toLocaleTimeString());
        }
    }, [totalLoginCount]);



    const LeadPageTitleBuild = () => {
        return (

            <div className="activity-title">
                <div className="title">
                    <div className="page-title" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <span>User Activity</span>
                    </div>

                    <div className="table-filter-section status-text">
                        <span className="status-label-text ">Last Updated:</span>{' '}
                        <span className="status-timestamp">
                            {lastUpdated}
                        </span>
                    </div>
                </div>

                <div className="stats-grid">
                    <div className="inactive-card">
                        <FaSignInAlt className="inactive-icon" size={24} />
                        <div className="inactive-content">
                            <h3 className="inactive-titles">Unapproved Activity in the Last 30 Days</h3>
                            <p className="inactive-count">
                                { inactiveActivityCount }
                            </p>
                        </div>
                    </div>
                    <div className="login-card">
                        <FaSignInAlt className="login-icon" size={24} />
                        <div className="login-content">
                            <h3 className="login-title">Today's Logins</h3>
                            <p className="login-count">
                                { totalLoginCount}
                            </p>
                        </div>
                    </div>
                    <div className="activity-card">
                        <FaSignInAlt className="activity-icon" size={24} />
                        <div className="activity-content">
                            <h3 className="activity-titles">Activity in the Last 30 Days</h3>
                            <p className="activity-count">
                                { activityTotalCount }
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        );
    };


    const LeadsTableBuild = () => {
        return (
            <div className="tmf-table_wrapper">
                <div
                    className={`tmf-datatable__wrapper tmf_hide_build_in_pagination`}
                    style={{
                        width: "100%",
                        maxWidth: "100%",
                        overflowX: "auto"
                    }}
                >
                    <div
                        style={{
                            borderTopColor: "#fff",
                            width: "1200px",
                            minWidth: "100%",
                            overflowX: "auto",
                        }} >

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                    backgroundColor: "#fff",
                                    width: "85%"
                                }}
                            >
                                <ActivityFilter
                                    activities={activities}
                                    setActivities={setActivities}
                                    loadFilterData={loadFilterData}
                                    setPageNo={setPageNo}
                                    setShouldLoadData={setShouldLoadData}
                                ></ActivityFilter>

                            </div>

                            <div className="table-filter-section pagination-width" >
                                <DTablePagination
                                    tPage={totalPage!}
                                    cPage={pageNo}
                                    onPageChange={(e) => setPageNo(e)}
                                    name="activity"
                                />
                            </div>


                        </div>

                        <div style={{ zIndex: "0" }}>
                            <ActivityTable
                                activityData={activityData}
                                isError={isError}
                                isPending={isLoading}
                                totalDataCount={totalPage}
                                pagination={pageNo}
                                setPagination={setPageNo}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            <LeadPageTitleBuild></LeadPageTitleBuild>

            <LeadsTableBuild></LeadsTableBuild>

        </>
    );
};

export default Activity;
