import { Reducer } from "redux";
import {
  OrganizationActionTypes,
  organizationFilterInitials,
} from "./action.types";

export const initialState: organizationState = {
  organizations: [],
  isLoading: false,
  allOrganizations: [],
  isAllOrganizationLoading: false,
  organizationTypes: [],
  showAddOrganizationModal: false,
  organizationFilters: organizationFilterInitials,
  isOrganizationLoading: { id: null, isStatusLoading: false },
};

const reducer: Reducer<organizationState> = (state = initialState, action) => {
  switch (action.type) {
    case OrganizationActionTypes.SET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: [...action.organizations],
      };
    }
    case OrganizationActionTypes.SET_ORGANIZATIONS_LOADER: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case OrganizationActionTypes.SET_ALL_ORGANIZATIONS: {
      return {
        ...state,
        allOrganizations: [...action.allOrganizations],
      };
    }
    case OrganizationActionTypes.SET_ALL_ORGANIZATION_LOADER: {
      return {
        ...state,
        isAllOrganizationLoading: action.isLoading,
      };
    }
    case OrganizationActionTypes.SET_ORGANIZATION_TYPES: {
      return {
        ...state,
        organizationTypes: [...action.organizationTypes],
      };
    }
    case OrganizationActionTypes.SET_ORGANIZATION_LOADER: {
      return {
        ...state,
        isOrganizationLoading: {
          ...state.isOrganizationLoading,
          ...action.isOrganizationLoading,
        },
      };
    }
    case OrganizationActionTypes.SET_SHOW_ADD_ORGANIZATION_MODAL: {
      return {
        ...state,
        showAddOrganizationModal: action.showAddOrganizationModal,
      };
    }
    case OrganizationActionTypes.SET_ORGANIZATION_FILTERS: {
      return {
        ...state,
        organizationFilters: {
          ...state.organizationFilters,
          ...action.organizationFilters,
        },
      };
    }

    case OrganizationActionTypes.SET_ORGANIZATION_STATUS: {
      return {
        ...state,
        organizations: state.organizations.map((el) => {
          if (el.organizationId === action.organization.organizationId) {
            el.isActive = action.organization.isActive;
          }
          return el;
        }),
      };
    }
    case OrganizationActionTypes.EDIT_ORGANIZATION_MODAL:{
      return{
        ...state,
        editOrganizationModal:{...state.editOrganizationModal, ...action.editOrganizationModal},
      };
    }
    case OrganizationActionTypes.DELETE_ORGANIZATION_MODAL:{
      return{
        ...state,
        deleteOrganizationModal:{...state.deleteOrganizationModal,...action.deleteOrganizationModal},
      };
    }
    case OrganizationActionTypes.UNDO_DELETE_ORGANIZATION_MODAL:{
      return{
        ...state,
        undoDeleteOrganizationModal:{...state.undoDeleteOrganizationModal,...action.undoDeleteOrganizationModal},
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as organizationReducer };
