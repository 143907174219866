import { CCard, CCardBody, CDataTable } from "@coreui/react";
import Loader from "components/loader/Loader";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api.service";
import Constants from "../../../constants/Constants";
import LicenseActions from "./LicenseActions";
import moment from "moment";

const LicenseDetails = () => {
  const [license, setLicense] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [popUpMenu, setPopUpMenu] = useState(false);

  let { organizationId }: { organizationId: string } = useParams();
  const [isSearchActive, setIsSearchActive] = useState(false);

  useEffect(() => {
    loadLicenseDetails();
  }, []);

  const loadLicenseDetails = () => {
    setLoading(true);
    api
      .get(Constants.LICENSE_DETAILS_URI + "/" + organizationId)
      .then((res) => {
        let myARRAY: any = [];
        myARRAY.push(res.data);
        setLicense(myARRAY);
      }).finally(() => setLoading(false));
  }





  const fields = [
    { key: "name", label: "Plan Name", _style: { width: "20%" } },
    {
      key: "isFreeTrial",
      label: "Status",
      _style: { width: "15%", textAlign: "center", },
    },
    { key: "msThirdPartySubscriptionID", label: "Subscription Activation", _style: { width: "14%", textAlign: "center", } },
    { key: "isPurchased", label: "Purchased", _style: { width: "14%", textAlign: "center", } },
    { key: "subscriptionDate", _style: { width: "14%", textAlign: "center" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "2%", textAlign: "center", paddingRight: "0.22rem" },
      sorter: false,
      filter: false,
    },
  ];

  const LicensePageTitleBuild = () => {
    return (

      <div className="title">
        <div className="page-title">License Details</div>
        <div>

          <Link className="create" to="/organization">
            Back
          </Link>

        </div>
      </div>

    );
  };

  const LicenseTableBuild = () => {
    return (

      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination    
            ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >

            <div
              className="table-filter-section"
            >
              {/* <TMFSearch
                sapi={Constants.ORGANIZATION_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="organizations"
                onCalcelSearch={() => cancelDateRange()}
              /> */}
              <div
                style={{ display: "flex", alignItems: "center", gap: "30px" }}
              >


                {/* {
                  !isSearchActive &&
                  <span style={{ marginLeft: "auto" }}>
                    <DTablePagination
                      cPage={pageNo}
                      tPage={totalPage!}
                      onPageChange={(e) => {
                        setPageNo(e);
                        loadDateRange();
                      }}
                    ></DTablePagination>
                  </span>
                } */}

              </div>
            </div>

            <CDataTable
              items={license}
              fields={fields}
              hover
              responsive={true}
              loading={loading}
              scopedSlots={{
                name: (item) => {
                  return (
                    <td style={{
                      filter: item.isDeleted ? "blur(0.6px)" : ""
                    }}
                    >
                      <div className="name"
                      >
                        {
                          !item.isActive ? (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#524467",
                                textDecoration: "line-through"
                              }}
                            >
                              {item.name ? item.name.charAt(0).toUpperCase() +
                                item.name.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          ) : (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "var(--txt-primary, #23005B)",
                              }}
                            >
                              {item.name ? item.name.charAt(0).toUpperCase() +
                                item.name.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          )
                        }

                        <span style={{
                          display: "flex",
                          gap: "0.8rem",
                          fontSize: "0.875rem",
                          fontWeight: "400"
                        }}>
                          <span >Created Date: <span style={{
                            fontWeight: "600"
                          }}>{item?.creationDate?.split("T")[0]}</span> </span>
                        </span>

                      </div>
                    </td>
                  )
                },
                isFreeTrial: (item) => {
                  return (
                    <td style={{ textAlign: "center" }}>
                      {item.isDeleted
                        ? <span style={{
                          display: 'inline-block',
                          padding: '8px 25px',
                          fontSize: '14px',
                          fontWeight: '700',
                          borderRadius: '20px',
                          backgroundColor: '#F5E6E6',  // Light grayish-red
                          color: '#C62828'            // Dark red
                        }}>
                          Deactivated
                        </span>
                        : !item.isActive ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#FFE4E4',  // Light red
                            color: '#D32F2F'            // Dark red
                          }}>
                            InActive
                          </span>
                        ) : !item.isFreePlan && item.isFreeTrial ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#FFF3E0',  // Light orange
                            color: '#E65100'            // Dark orange
                          }}>
                            Trial
                          </span>
                        ) : item.isFreePlan && !item.isFreeTrial ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '15px',
                            backgroundColor: '#E8EAF6',  // Light indigo
                            color: '#303F9F'            // Dark indigo
                          }}>
                            Free
                          </span>
                        ) : !item.isFreePlan && !item.isFreeTrial && item.isActive ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#E0F2F1',  // Light teal
                            color: '#00695C'            // Dark teal
                          }}>
                            Active
                          </span>
                        ) : (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#ECEFF1',  // Light blue grey
                            color: '#455A64'            // Dark blue grey
                          }}>
                            N/A
                          </span>
                        )}
                    </td>
                  );
                },

                msThirdPartySubscriptionID: (item) => {
                  return (
                    <td style={{ textAlign: "center" }}>
                      {item.isDeleted
                        ? "-----"
                        : item.msThirdPartySubscriptionID != null ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#E1F5FE',  // Light blue
                            color: '#0277BD'            // Dark blue
                          }}>
                            Activated
                          </span>
                        ) : (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#FFF8E1',  // Light amber
                            color: '#FF8F00'            // Dark amber
                          }}>
                            Pending Activation
                          </span>
                        )}
                    </td>
                  );
                },

                isPurchased: (item) => {
                  return (
                    <td style={{ textAlign: "center" }}>
                      {item.isDeleted
                        ? "-----"
                        : item.isPurchased ? (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#E8F5E9',  // Light green
                            color: '#2E7D32'            // Dark green
                          }}>
                            License Purchased
                          </span>
                        ) : (
                          <span style={{
                            display: 'inline-block',
                            padding: '8px 25px',
                            fontSize: '14px',
                            fontWeight: '700',
                            borderRadius: '20px',
                            backgroundColor: '#FFF3E0',  // Light orange
                            color: '#E65100'            // Dark orange
                          }}>
                            Purchase Pending
                          </span>
                        )}
                    </td>
                  );
                },
                subscriptionDate: (item) => {
                  return (
                    <>
                      {
                        item.isFreePlan || item.isDeleted ? "-----" : (
                          <>
                            {
                              item.isFreeTrial && !item.isFreePlan ? (

                                <td
                                  style={{ textAlign: "center" }}
                                  className={
                                    moment(item.trialEndDate).format("YYYY-MM-DD") <=
                                      moment().format("YYYY-MM-DD")
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  <label>{moment(item.trialStartDate).format("MM/DD/YYYY")}</label>
                                  <br />
                                  <label>{moment(item.trialEndDate).format("MM/DD/YYYY")}</label>
                                </td>
                              ) : (
                                <td
                                  style={{ textAlign: "center" }}
                                  className={
                                    moment(item.endDate).format("YYYY-MM-DD") <=
                                      moment().format("YYYY-MM-DD")
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  <label>{moment(item.startDate).format("MM/DD/YYYY")}</label>
                                  <br />
                                  <label>{moment(item.endDate).format("MM/DD/YYYY")}</label>
                                </td>

                              )
                            }
                          </>
                        )
                      }
                    </>
                  );
                },
                Action: (item: IOrganization) => (
                  <td>
                    <LicenseActions
                      license={item}
                    ></LicenseActions>
                  </td>
                ),
              }}
            />

            {/* {!isSearchActive &&
              (
                <span style={{ marginLeft: "auto" }}>
                  <DTablePagination
                    cPage={pageNo}
                    tPage={totalPage!}
                    onPageChange={(e) => {
                      setPageNo(e);
                      loadDateRange();
                    }}
                  ></DTablePagination>
                </span>
              )
            } */}
          </div>
        </div>
      </div>


    );
  };

  return (

    <>
      <LicensePageTitleBuild />
      <LicenseTableBuild />

    </>

  );
};

export default LicenseDetails;
