export enum PlanActionTypes {
  SET_ALL_PLANS = "@@plan/SET_ALL_PLANS",
  SET_PLAN_FILTER_DATA_LOADER = "@@plan/SET_PLAN_FILTER_DATA_LOADER",
  ADD_PLAN_MODAL = "@@plan/ADD_PLAN_MODAL",
  ADD_PLAN = "@@plan/ADD_PLAN",
  RESET_ADD_PLAN_MODAL = "@@plan/RESET_ADD_PLAN_MODAL",
  EDIT_PLAN_MODAL = "@@plan/EDIT_PLAN_MODAL",
  RESET_EDIT_PLAN_MODAL = "@@plan/RESET_EDIT_PLAN_MODAL",
  RESET_VIEW_PLAN_MODAL = "@@plan/RESET_VIEW_PLAN_MODAL", 
  RESET_DELETE_PLAN_MODAL = "@@plan/RESET_DELETE_PLAN_MODAL",
  UPDATE_PLAN = "@@plan/UPDATE_PLAN",
  VIEW_PLAN = "@@plan/VIEW_PLAN",
  VIEW_PLAN_MODAL = "@@plan/VIEW_PLAN_MODAL",
  DELETE_PLAN_MODAL = "@@plan/DELETE_PLAN_MODAL",
  DELETE_PLAN = "@@plan/DELETE_PLAN",
  GET_PLAN_BY_ID="@@plan/GET_PLAN_BY_ID",
  GET_PLAN_BY_ID_LOADER="@@plan/GET_PLAN_BY_ID_LOADER",
  CHECK_PLAN_EXIST="@@plan/CHECK_PLAN_EXIST"
}

export const plansFilterInitial: IPlanFilter = {
  BeginDate: null,
  EndDate: null,
  IsActive: null,
};

