import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import ViewOrganizationDetails from "components/modals/organization/ViewOrganizationDetails";
import { Dispatch, useState } from "react";
import { CiCreditCard1, CiUndo } from "react-icons/ci";
import { FaPrint, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { setDeleteOrganizationModal, setEditOrganizationModal, setUndoDeleteOrganizationModal } from "store/organization/action";

interface IOrganizationActionParams {
  organization: IOrganization;
}

const OrganizationActions = ({ organization }: IOrganizationActionParams) => {
  const [viewOrgDetails, setViewOrgDetails] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();

  const usersFilter = useSelector(
    (state: ApplicationState) => state.user.usersFilter
  );

  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center", outline: "none", border: "none" }}>
      <button
        onClick={() => {
          setViewOrgDetails(true);
        }}
        style={{
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          padding: "0px 15px",
          cursor: organization.isDeleted ? "not-allowed" : ""
        }}
        disabled={organization.isDeleted}
      >
        View
      </button>



      <TmfDropdown>
        {
          organization.isDeleted ? (

            <TmfDropdownItem
              icon={<CiUndo />}
              text="Undo Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setUndoDeleteOrganizationModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      organization,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>

          ) : (
            <>
              <TmfDropdownItem
                icon={<GoPencil />}
                text="Edit"
                props={{
                  onClick: () =>
                    // handleEditOrganizationModal(organization.organizationId),
                    dispatch(
                      setEditOrganizationModal({
                        showModal: true,
                        isModalFormSubmitting: false,
                        organization,
                      })
                    ),
                }}
              ></TmfDropdownItem>

              {/* {organization.totalSubscriptionCount > 0 && (
                <TmfDropdownItem
                  icon={<CiCreditCard1 />}
                  text="Subscriptions"
                  props={{ to: `/subscription/${organization.organizationId}` }}
                ></TmfDropdownItem>
              )} */}

              {organization.totalSubscriptionCount > 0 && (
                <TmfDropdownItem
                  icon={<FaPrint />}
                  text="License"
                  props={{ to: `/license/${organization.organizationId}` }}
                ></TmfDropdownItem>
              )}

              {organization.totalUserCount > 0 && (
                <TmfDropdownItem
                  icon={<FaRegEye />}
                  text="View User"
                  props={{
                    onClick: () => {
                      usersFilter.OrganizationID = organization.organizationId;
                      usersFilter.IsActive = null;
                    },
                    to: `/users`,
                  }}
                ></TmfDropdownItem>
              )}


              <TmfDropdownItem
                icon={<FaRegTrashAlt />}
                text="Delete"
                props={{
                  onClick: () => {
                    dispatch(
                      setDeleteOrganizationModal({
                        showModal: true,
                        isModalFormSubmitting: false,
                        organization,
                      })
                    );
                  },
                }}
              ></TmfDropdownItem>
            </>
          )
        }

      </TmfDropdown>




      {viewOrgDetails && (
        <ViewOrganizationDetails
          isOpen={viewOrgDetails}
          onClose={() => setViewOrgDetails(false)}
          organization={organization}
        />
      )}
    </div>
  );
};

export default OrganizationActions;
