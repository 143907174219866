
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent
} from "@coreui/react";
import moment from "moment";

import { useState } from "react";


function ViewLicenseDetail({ isOpen, onClose, license }) {
    const [activeKey, setActiveKey] = useState(1);


    return (
        <div>
            {isOpen && (
                <CModal
                    size="lg"
                    show={isOpen}
                    onClose={onClose}
                    closeOnBackdrop={false}
                    // style={{ maxHeight: "90vh", overflowY: "scroll" }}
                    style={{ borderTop: "5px solid #ccc" }}
                >
                    <CModalHeader closeButton>
                        <CModalTitle>
                            <div>License's Details</div>
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CNav variant="tabs" role="tablist">
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    active={activeKey === 1}
                                    onClick={() => setActiveKey(1)}
                                >
                                    General
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    active={activeKey === 2}
                                    onClick={() => setActiveKey(2)}
                                >
                                    License
                                </CNavLink>
                            </CNavItem>



                            <CNavItem>
                                <CNavLink
                                    href="javascript:void(0);"
                                    active={activeKey === 4}
                                    onClick={() => setActiveKey(4)}
                                >
                                    Date
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>


                            {activeKey === 1 ? (
                                <div className="container">
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">
                                                Plan Name :
                                            </span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.name ? license.name : "N/A"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />

                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Subscription Type:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.subscriptionType ? license.subscriptionType : "N/A"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">MsThirdPartySubscriptionID:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.msThirdPartySubscriptionID ?? "N/A"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">SKUID:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.skuid ?? "N/A"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">AutoRenew:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.autoRenew ? "Yes" : "No"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Trial:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.isFreeTrial ? "Yes" : "No"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Free Plan:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.isFreePlan ? "Yes" : "No"}</span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Purchased:</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>{license.isPurchased ? "Yes" : "No"}</span>
                                        </div>
                                    </div>



                                </div>
                            ) : (
                                ""
                            )}


                            {activeKey === 4 ? (
                                <div className="container">
                                    {
                                        license.isFreeTrial && !license.isFreePlan && (
                                            <>
                                                <div className="row p-2">
                                                    <div className="col-md-6">
                                                        <span className="font-weight-bold">Trial Start Date:</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>
                                                            {moment(license.trialStartDate).format(
                                                                "MMM DD YYYY"
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <div className="row p-2">
                                                    <div className="col-md-6">
                                                        <span className="font-weight-bold">Trial End Date:</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>
                                                            {moment(license.trialEndDate).format("MMM DD YYYY")}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                            </>
                                        )
                                    }


                                    {
                                        !license.isFreeTrial && !license.isFreePlan && (
                                            <>
                                                <div className="row p-2">
                                                    <div className="col-md-6">
                                                        <span className="font-weight-bold">Start Date:</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>
                                                            {moment(license.startDate).format(
                                                                "MMM DD YYYY"
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <div className="row p-2">
                                                    <div className="col-md-6">
                                                        <span className="font-weight-bold">End Date:</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>
                                                            {moment(license.endDate).format("MMM DD YYYY")}
                                                        </span>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }
                                </div>
                            ) : (
                                ""
                            )}
                            {activeKey === 2 ? (
                                <div className="container">
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Purchased License :</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>
                                                {license.purchasedLicenses ?? "N/A"}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Remaining License :</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>
                                                {license.remainingLicence ?? "N/A"}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">Bookmark Count :</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>
                                                {license.allowedNumberOfBookmarks ?? "N/A"}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="row p-2">
                                        <div className="col-md-4">
                                            <span className="font-weight-bold">AICopilot Licenses :</span>
                                        </div>
                                        <div className="col-md-8">
                                            <span>
                                                {license.allowedNoOfAICopilotUser ?? "N/A"}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            ) : (
                                ""
                            )}
                        </CTabContent>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="danger" size="sm" onClick={onClose}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>
            )}
        </div>
    );
}

export default ViewLicenseDetail;
