import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";
import { BsFillPersonFill } from "react-icons/bs";
// import ConvertImageToBinaryFile from "helper/ConvertImageToBinaryFile";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import PhoneNumberValidation from "helper/PhoneNumberValidation";

const EditOrganizationModal = ({ refreshDetails, organizationId, isOpen, onClose }) => {
  const [organizationEdit, setOrganizationEdit] = useState<IOrganization | undefined>(undefined);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  // const [binaryFile, setBinaryFile] = useState<File | null>(null);


  const [isloading, setLoaded] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [isSpinning, setSpinning] = useState(false);
  const [isFaxCheckError, setFaxCheckError] = useState<boolean>(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isNameExist, setIsNameExist] = useState(false);

  const { countries, timezones } = useSelector((state: ApplicationState) => state.global);


  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();


  const selectedUserImage = watch("logo");


  const loadEdit = () => {
    setLoaded(true);
    api.post(Constants.ORGANIZATION_EDIT_URI + "/" + organizationId)
      .then((res) => {
        setOrganizationEdit(res.data);
        setIsSuccess(true);
      })
      .finally(() => setLoaded(false));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(new Blob([file]));
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const MAX_IMAGE_SIZE = 5 * 1024 * 1024;

    if (file) {
      if (file.size > MAX_IMAGE_SIZE) {
        toast.error("Image size exceeds 5MB limit.");
        setValue("logo", null);
      } else {
        const base64 = await convertToBase64(file);
        setValue("logo", base64);
      }
    }


  };

  const allTimezone = timezones.map((item) => {
    return {
      label: item.displayName,
      value: item?.id,
    };
  });

  const allCountry = countries.map((item) => {
    return {
      label: item.name,
      value: item?.id,
    };
  });

  const getStateList = (countryID) => {
    api.get(Constants.STATE_GET_URI + "/" + countryID).then((res) => {
      const allState = res.data.map((item) => {
        return {
          label: item.name,
          value: item?.id,
        };
      });
      setStateList(allState);
    })
  }

  function loadStateList(countryID) {
    api.get(Constants.STATE_GET_URI + "/" + countryID).then((res) => {
      const allState = res.data.map((item) => {
        return {
          label: item.name,
          value: item?.id,
        };
      });
      setStateList(allState);
    });
  }

  const FaxValidation = (e) => {
    const faxNumber = e.target.value;

    const faxRegex = /^(\+?[1-9]{1}[0-9]{1,3})?[-\s]?(\(?\d{1,4}\)?[-\s]?)?[\d]{1,4}[-\s]?\d{1,4}[-\s]?\d{1,4}$/;
    if (!faxRegex.test(faxNumber) && faxNumber.length > 0) {
      setFaxCheckError(true);
    } else {
      setFaxCheckError(false);
    }
  };

  const onSubmit = (data) => {
    setSpinning(true);
    api
      .post(Constants.ORGANIZATION_UPDATE_POST_URI + "/" + organizationId, data)
      .then(async () => {
        toast.success("😊 Organization edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        ////console.log(`Organization updated successfully!`);
        refreshDetails();
      }).catch((error) => {
        toast.error(`😔 Error while editing organization!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => setSpinning(false));
  };

  //Set initial Image
  // useEffect(() => {
  //   if (binaryFile) {
  //     binaryFile && setValue("logo", binaryFile)
  //   }
  // }, [binaryFile])

  useEffect(() => {
    loadEdit();
  }, []);

  useEffect(() => {
    if (organizationEdit) {
      setValue("name", organizationEdit.name);
      setValue("email", organizationEdit.email);
      setValue("website", organizationEdit.website);
      setValue("phone", organizationEdit.phone);
      setValue("fax", organizationEdit.fax ?? "");
      setValue("city", organizationEdit.city);
      setValue("description", organizationEdit.description ?? "")
      setValue("timezone", organizationEdit.timezone)
      setValue("country", organizationEdit.country)
      setValue("state", organizationEdit.state)
      setValue("address", "")
      setValue("logo", organizationEdit.logo)
      // ConvertImageToBinaryFile(organizationEdit.logo, "image.png", setBinaryFile);
      loadStateList(organizationEdit.country)
    }
  }, [isSuccess])


  const onNameChange = (e) => {
    let name = e.target.value;
    if (e.target.value.trim() !== '') {
      if (e.target.value.trim() === organizationEdit?.name) {
        setIsNameExist(false)
        return;
      } else {
        api.post(Constants.ORGANIZATION_NAME_EXIST, {
          name: name
        })
          .then(async (response) => {


            setIsNameExist(response.data);
            if (!response.data) {
              setValue("name", e.target.value);

            }

          }).catch((error) => {
            toast.error(error.message);
          })
      }
    } else {
      return;
    }
  };

  const onEmailChange = (e) => {
    let email = e.target.value;
    if (e.target.value.trim() !== '') {
      if (e.target.value.trim() === organizationEdit?.email) {
        setIsEmailExist(false);
        return;
      } else {
        api.post(Constants.ORGANIZATION_EMAIL_EXIST, {
          email: email
        })
          .then(async (response) => {
            setIsEmailExist(response.data);
            if (!response.data) {
              setValue("email", e.target.value);
            }
          }).catch((error) => {
            toast.error(error.message);
          })
      }
    } else {
      return;
    }
  };




  const NameField = register("name", {
    required: true,
  });

  const EmailField = register("email", {
    required: true,
  });

  const PhoneNumberField = register("phone", {
    required: true,
  });



  return (
    <div>
      {!isloading && organizationEdit && (
        <CModal size="lg" show={isOpen} onClose={onClose} closeOnBackdrop={false}>
          <CModalHeader closeButton>
            <CModalTitle>
              {" "}
              <div>Edit Organization</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px", padding: "0px 10px 10px 10px" }}
          >
            <CModalBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="logo">Logo</label>
                    <div className="mt-1 " style={{
                      display: "flex",
                      gap: 2
                    }}>
                      {/* Image Upload */}

                      <span className='profile-image-container'>

                        {selectedUserImage ? (
                          selectedUserImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(selectedUserImage)}
                              alt="Uploaded"
                              className='profile-image'
                            />
                          ) : (
                            <img
                              src={selectedUserImage}
                              alt="Uploaded"
                              className='profile-image'
                            />
                          )
                        ) : (
                          <>
                            {
                              organizationEdit.logo ? (
                                <img
                                  src={organizationEdit.logo}
                                  alt="Organization Logo"
                                  className='profile-image'
                                />
                              ) : (
                                <BsFillPersonFill className='profile-icon' />
                              )
                            }
                          </>
                        )}
                      </span>
                      {/* Hidden file input */}
                      <input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg"
                        className="sr-only"
                        onChange={(e) => handleFileUpload(e)}
                      />


                      {/* Choose Image Button */}
                      <label
                        htmlFor="file-upload"
                        className='choose-image-button'
                      >
                        Choose Image
                      </label>
                    </div>
                  </CFormGroup>
                </CCol>

                <CCol>

                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="name">Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      {...NameField}
                      onBlur={(e) => onNameChange(e)}
                    />
                    <small className="text-danger">
                      {errors.name?.type === "required" && "Name is required"}
                      {isNameExist ? "Name already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="email">E-mail*</label>
                    <input
                      type="email"
                      className="form-control "
                      {...EmailField}
                      onBlur={(e) => onEmailChange(e)}
                    />


                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                      {isEmailExist ? "Email Address already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="website">Website</label>
                    <input
                      type="url"
                      placeholder="Enter a Website URL"
                      {...register("website")}
                      className="form-control "
                      name="website"
                    />
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="phone">Phone Number*</label>
                    <input
                      type="text"
                      className="form-control "
                      onKeyDown={PhoneNumberValidation}
                      maxLength={10}
                      {...PhoneNumberField}
                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="fax">Fax*</label>
                    <input
                      type="number"
                      className="form-control "
                      onKeyDown={FaxValidation}
                      {...register("fax", { required: true })}
                    />
                    <small className="text-danger">
                      {errors.fax?.type === "required" &&
                        "Fax Number is required"}
                      {isFaxCheckError && ("Inavlid Fax Number")}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="country">Country*</label>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            getStateList(e.target.value);
                          }}
                          className="form-control"
                          value={field.value || organizationEdit.country}

                        >
                          {allCountry.map((country, index) => {
                            return (
                              <option key={`${country.value}-${index}`} value={country.value}>
                                {country.label}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.country?.type === "required" &&
                        "Country is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="state">State* </label>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          className="form-control"
                          value={field.value || organizationEdit.state}>

                          {stateList.map((state, index) => {
                            return (
                              <option key={index} value={state["value"]}>
                                {state["label"]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.state?.type === "required" && "State is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      className="form-control  "
                      {...register("city")}
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="timezone">TimeZone*</label>
                    <Controller
                      name="timezone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          value={field.value || organizationEdit.timezone}
                          onChange={(e) => {
                            field?.onChange(e);
                          }}
                          className="form-control"
                        >

                          {allTimezone.map((timezone, index) => {
                            return (
                              <option key={`${timezone.value}-${index}`} value={timezone.value}>
                                {timezone.label}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />


                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
              <CCol>
                  <CFormGroup>
                    <label htmlFor="email">Admin E-mail*</label>
                    <input
                      type="email"
                      className="form-control "
                      {...EmailField}
                      onBlur={(e) => onEmailChange(e)}
                    />


                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                      {isEmailExist ? "Email Address already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
              
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control "
                      {...register("description")}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>

            {!isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton style={{ background: "var(--primary)", color: "white" }}
                  disabled={isEmailExist || isNameExist}
                  type="submit" size="sm">
                  Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="danger" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
            {isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton disabled style={{ background: "var(--primary)", color: "white" }}
                  size="sm">
                  <i className="fa fa-spinner fa-spin"></i> Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton color="danger" onClick={onClose} size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
          </form>
        </CModal>
      )}
      {isloading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default EditOrganizationModal;
