const PlansTableVariables = {
  fields: [
    { key: "name", _style: { width: "25%" } },
    { key: "skuid", _style: { width: "15%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "minimumLicenceCount",label:"License Count", _style: { width: "15%",textAlign:"center" } },
    { key: "allowedNumberOfBookmarks",label:" Bookmarks Count", _style: { width: "15%",textAlign:"center" } },
      { key: "subscriptionType", _style: { width: "15%",textAlign:"center" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "5%" },
      sorter: false,
      filter: false,
    },
  ],
};

export default PlansTableVariables;
