import Loader from "components/loader/Loader";
import { ReactNode } from "react";
import Input from "./input";
import Textarea from "./textarea";
import SearchLoader from "components/loader/SearchLoader";

interface IInputError {
  errorText: string;
  hasError: boolean;
}
interface IInputProps extends React.ComponentProps<"input"> {
  label?: string;
  isLoading?: boolean;
  error?: IInputError;
  inputType?: "input" | "textarea" | "Skuid";
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  input?: ReactNode;
  suffix?: ReactNode;
  checkSkuidExist?: (keyword: string) => void;
  isCheckingLoading?: boolean;
  checkExist?: boolean;
  errorMessage?: string;
  InvalidError?:boolean
  value?:string
}

const AvishInput = ({
  label,
  name,
  type,
  onChange,
  placeholder,
  isLoading = false,
  disabled,
  inputType = "input",
  error = {
    errorText: "This field is required",
    hasError: false,
  },
  required = false,
  autoFocus = false,
  maxLength,
  defaultValue,
  input,
  suffix,
  className,
  checkExist,
  isCheckingLoading,
  checkSkuidExist,
  InvalidError=false,
  errorMessage,
  value
}: IInputProps) => {
  const inputTypeSelectorBuild = () => {
    var inputComponent: JSX.Element;
    switch (inputType) {
      case "textarea": {
        inputComponent = (
          <Textarea
            name={name}
            placeholder={placeholder}
            disabled={isLoading || disabled}
            onChange={onChange}
            required={required}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            value={ value}
          ></Textarea>
        );
        break;
      }
      case "Skuid": {
        inputComponent = (
          <Input
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={isLoading || disabled}
            onChange={onChange}
            onBlur={(e) => {
              if (checkSkuidExist) {
                checkSkuidExist(e.target.value);
              }
            }}
            required={required}
            autoFocus={autoFocus}
            maxLength={maxLength}
            defaultValue={defaultValue}
            value={ value}
          ></Input>
        );
        break;
      }
      default: {
        inputComponent = (
          <Input
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={isLoading || disabled}
            onChange={onChange}
            required={required}
            autoFocus={autoFocus}
            maxLength={maxLength}
            defaultValue={defaultValue}
            value={ value}
          ></Input>
        );
        break;
      }
    }

    return inputComponent;
  };

  return (
    <div
      className={[
        "form-element",
        error.hasError|| InvalidError ? "error" : "",
        isLoading ? "loading" : "",
        className,
      ].join(" ")}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <span className={`input-wrapper ${className}`}>
        {input || (
          <>
            {inputTypeSelectorBuild()}
            {isLoading && <Loader></Loader>}
            {isCheckingLoading && (
              <div className="skuid-loading">
                <SearchLoader></SearchLoader>
              </div>
            )}
          </>
        )}

        {suffix && <div className="input-suffix">{suffix}</div>}
  
      </span>
      {
        checkExist &&
        <div className="input-error-message">
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{errorMessage}</span>
        </div>
      }
      {error.hasError && (
        <div className="input-error-message">
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error.errorText}</span>
        </div>
      )}
      
    </div>
  );
};

export default AvishInput;
