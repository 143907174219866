import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { TagMyFavLogo } from "assets/icons/TagMyFavLogo";
import PhoneNumberValidation from "helper/PhoneNumberValidation";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch } from "redux";
import api from "services/api.service";
import { ApplicationState } from "store";
import { setAddOrganizationModal } from "store/organization/action";
import Constants from "../../../constants/Constants";

const AddOrganizationModal = ({ refreshDetails }) => {

  const dispatch: Dispatch<any> = useDispatch();
  const { countries, timezones } = useSelector((state: ApplicationState) => state.global);
  const showAddOrganizationModal = useSelector((state: ApplicationState) => state.organization.showAddOrganizationModal);
  const organizationTypes = useSelector((state: ApplicationState) => state.organization.organizationTypes);

  const [organization, setOrganization] = useState<IOrganization>({} as IOrganization);
  const [isFaxCheckError, setFaxCheckError] = useState<boolean>(false);
  const [selectedUserImage, setSelectedUserImage] = useState<any>("")
  const [stateList, setStateList] = useState([]);
  const [isSpinning, setSpinning] = useState<boolean>(false);

  const [isNameExist, setIsNameExist] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isAdminEmailExist, setIsAdminEmailExist] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (showAddOrganizationModal) {
      organization.country = countries[0]?.id;
      loadStateList(organization.country);
    }
  }, [showAddOrganizationModal]);

  const onInputChange = (e) => {
    setOrganization({ ...organization!, [e.target.name]: e.target.value });

  };


  const onNameChange = (e) => {
    let name = e.target.value;
    if (e.target.value.trim() !== '') {
      api.post(Constants.ORGANIZATION_NAME_EXIST, {
        name: name
      })
        .then(async (response) => {

          setIsNameExist(response.data);
          if (!response.data) {
             setValue("name", e.target.value);
            // setOrganization({ ...organization!, [e.target.name]: e.target.value });
          }

        }).catch((error) => {
          toast.error(error.message);
        })
    } else {
      return;
    }
  };

  const onEmailChange = (e) => {
    let email = e.target.value;
    if (e.target.value.trim() !== '') {
      api.post(Constants.ORGANIZATION_EMAIL_EXIST, {
        email: email
      })
        .then(async (response) => {

          setIsEmailExist(response.data);
          if (!response.data) {
            // setValue("name", e.target.value);
            setOrganization({ ...organization!, [e.target.name]: e.target.value });
          }

        }).catch((error) => {
          toast.error(error.message);
        })
    } else {
      return;
    }
  };

  const onAdminEmailChange = (e) => {
    let email = e.target.value;
    if (e.target.value.trim() !== '') {
      api.post(Constants.USER_EXIST_BY_EMAIL, {
        email: email
      })
        .then(async (response) => {

          setIsAdminEmailExist(response.data);
          if (!response.data) {
            // setValue("name", e.target.value);
            setOrganization({ ...organization!, [e.target.name]: e.target.value });
          }

        }).catch((error) => {
          toast.error(error.message);
        })
    } else {
      return;
    }
  };








  //Loading state after selecting country
  function loadStateList(countryID) {
    api.get(Constants.STATE_GET_URI + "/" + countryID).then((res) => {
      const allState = res.data.map((item) => {
        return {
          label: item.name,
          value: item?.id,
        };
      });
      setStateList(allState);
      organization.state = allState[0].value;
    });
  }

  const allCountry = countries.map((item) => {
    return {
      label: item.name,
      value: item?.id,
    };
  });

  const allTimezone = timezones.map((item) => {
    return {
      label: item.displayName,
      value: item?.id,
    };
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(new Blob([file]));
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      const base64 = await convertToBase64(file);
      setSelectedUserImage(base64);
      // setOrganization({ ...organization!, logo: base64 });
      setValue("logo",base64);
    }
  };

  const FaxValidation = (e) => {
    const faxNumber = e.target.value;

    const faxRegex = /^(\+?[1-9]{1}[0-9]{1,3})?[-\s]?(\(?\d{1,4}\)?[-\s]?)?[\d]{1,4}[-\s]?\d{1,4}[-\s]?\d{1,4}$/;
    if (!faxRegex.test(faxNumber) && faxNumber.length > 0) {
      setFaxCheckError(true);
    } else {
      setFaxCheckError(false);
    }
  };

  const onSubmit = (data) => {
    setSpinning(true);

    const newOrganizationData = {
      ...data,
      address: ""
    }

    api
      .post(Constants.ORGANIZATION_CREATE_POST_URI, newOrganizationData)
      .then(async (response) => {
        toast.success("😊 Organization added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        dispatch(setAddOrganizationModal(false));
        setSpinning(false);
        refreshDetails();
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.error(`😔 ${error.response}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error(`😔 Error while adding organization!`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }).finally(() => {
        setSpinning(false);
      });
  };

  const PhoneNumberField = register("phone", {
    required: true,
  });
  const FaxField = register("fax", {
    required: true,
  });


  return (
    <>
      {showAddOrganizationModal && (
        <CModal
          size="lg"
          show={showAddOrganizationModal}
          onClose={() => {
            dispatch(setAddOrganizationModal(false));
          }}
          closeOnBackdrop={false}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Add Organization</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
            autoComplete="off"
          >
            <CModalBody>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="logo">Logo</label>
                    <div className="mt-1 " style={{
                      display: "flex",
                      gap: 2
                    }}>
                      {/* Image Upload */}

                      <span className='profile-image-container'>

                        {selectedUserImage ? (
                          selectedUserImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(selectedUserImage)}
                              alt="Uploaded"
                              className='profile-image'
                            />
                          ) : (
                            <img
                              src={selectedUserImage}
                              alt="Uploaded"
                              className='profile-image'
                            />
                          )
                        ) : (
                          <img
                            src={TagMyFavLogo}
                            alt="Uploaded"
                            className='profile-image'
                          />
                        )}
                      </span>
                      {/* Hidden file input */}
                      <input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg"
                        className="sr-only"
                        onChange={(e) => handleFileUpload(e)}
                      />


                      {/* Choose Image Button */}
                      <label
                        htmlFor="file-upload"
                        className='choose-image-button'
                      >
                        Choose Image
                      </label>
                    </div>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Name*</label> 

                    <input
                      type="text"
                      className="form-control"

                      placeholder="Enter an Organization Name"
                      {...(register("name", { required: true }))}
                      onBlur={(e) => onNameChange(e)}
                    
                    />
                    <small className="text-danger">
                      {errors.name?.type === "required" && "Name is required"}
                      {isNameExist ? "Name already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Type*</label>
                    <Controller
                      name="organizationTypeId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          value={field?.value || ""}
                          onChange={(e) => {
                            field.onChange(e);
                            // setOrganization({
                            //   ...organization!,
                            //   organizationTypeId: parseInt(e.target.value),
                            // });
                            setValue("organizationTypeID", e.target.value);
                          }}
                        >
                          <option value="" disabled >
                            Select a Type
                          </option>
                          {organizationTypes.map((item, index) => {
                            return (
                              <option value={item.organizationTypeId} key={index}>
                                {item.organizationType}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.organizationTypeId?.type === "required" &&
                        "OrganizationType is required"}
                    </small>

                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter an Email Address"
               
                      {...(register("email", { required: true }))}
                      onBlur={(e) => onEmailChange(e)}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email Addres is required"}
                      {isEmailExist ? "Email Address already exists" : null}
                    </small>
                  </CFormGroup>

                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number*</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter phone number"
                      {...PhoneNumberField}
                      maxLength={15}
                      onKeyDown={PhoneNumberValidation}
                      onChange={(e) => {
                        PhoneNumberField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Fax*</label>
                    <input
                      placeholder="Enter a Fax number"
                      onKeyDown={FaxValidation}
                      type="number"
                      className="form-control "
                      {...FaxField}
                      onChange={(e) => {
                        FaxField.onChange(e);
                        onInputChange(e)
                      }}
                    />
                    <small className="text-danger">
                      {errors.fax?.type === "required" &&
                        "Fax Number is required"}
                      {isFaxCheckError && ("Inavlid Fax Number")}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="country">Country*</label>
                    <Controller
                      name="country"
                      control={control}

                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          value={field?.value || ""}
                          onChange={(e) => {
                            field.onChange(e);

                            const selectedCountryID = parseInt(e.target.value);
                            setOrganization((prevOrganization) => ({
                              ...prevOrganization,
                              country: selectedCountryID,
                            }));
                            loadStateList(selectedCountryID);
                          }}
                        >
                          <option value="" disabled >
                            Select a Country Name
                          </option>
                          {allCountry.map((country, index) => {
                            return (

                              <option key={index} value={country.value}>
                                {country.label}
                              </option>

                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.country?.type === "required" &&
                        "Country is required"}
                    </small>
                  </CFormGroup>
                </CCol>

              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="timezone">Timezone*</label>
                    <Controller
                      name="timezone"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          value={field?.value || ""}
                          onChange={(e) => {
                            field.onChange(e);

                            setOrganization({
                              ...organization!,
                              timezone: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value="" disabled>
                            Select a TimeZone
                          </option>
                          {allTimezone.map((timezone, index) => {
                            return (
                              <option key={index} value={timezone["value"]}>
                                {timezone["label"]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="state">State*</label>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          value={field?.value || ""}
                          onChange={(e) => {
                            field.onChange(e);
                            setOrganization({
                              ...organization!,
                              state: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled >
                            Select a State
                          </option>
                          {stateList.map((state, index) => {
                            return (
                              <option key={index} value={state["value"]}>
                                {state["label"]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.state?.type === "required" && "State is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="city">City</label>
                    <input
                      placeholder="Enter a City"
                      type="text"
                      maxLength={100}
                      className="form-control"
                      name="city"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>


              <CRow>

                <CCol>
                  <CFormGroup>
                    <label htmlFor="website">Website</label>
                    <input
                      placeholder="Enter a Website URL"
                      type="url"
                      className="form-control "
                      name="website"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>

                <CCol>
                  <CFormGroup>
                    <label>Admin E-mail*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter an User Email Address"
                      {...(register("adminEmail", { required: true }))}
                      onBlur={(e) => onAdminEmailChange(e)}
                    />
                    <small className="text-danger">
                      {errors.adminEmail?.type === "required" && "User Email Address is required"}
                      {isAdminEmailExist ? "User Email Address  already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label htmlFor="description">Description</label>
                    <textarea
                      placeholder="Provide a Description"
                      className="form-control "
                      name="description"
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>
              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                    marginBottom: "0.5rem",
                  }}
                >
                  <CButton
                    style={{
                      background: "var(--primary)",
                      color: "white",
                      cursor: isEmailExist || isAdminEmailExist || isNameExist ? "not-allowed" : "pointer",
                    }}
                    type="submit"
                    size="sm"
                    disabled={isEmailExist || isAdminEmailExist || isNameExist}
                  >
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton
                      color="danger"
                      onClick={() => {
                        dispatch(setAddOrganizationModal(false));
                      }}
                      size="sm"
                    >
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                    marginBottom: "0.5rem",
                  }}
                >
                  <CButton
                    disabled
                    style={{ backgroundColor: "#1C6387", color: "white" }}
                    size="sm"
                  >
                    <i className="fa fa-spinner fa-spin"></i>Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton
                      color="danger"
                      onClick={() => {
                        dispatch(setAddOrganizationModal(false));
                      }}
                      size="sm"
                    >
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </CModalBody>
          </form>
        </CModal>
      )}
    </>
  );
};

export default AddOrganizationModal;
