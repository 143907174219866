import {
  CButton,
  CDataTable
} from "@coreui/react";
import axios from "axios";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import ImportLeads from "components/modals/leads/ImportLeads";
import LeadsMailModal from "components/modals/mail/LeadsMailModal";
import Constants from "constants/Constants";
import commonConstants from "constants/commonConstants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { BiSelectMultiple } from "react-icons/bi";
import { MdOutlineImportExport } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import leadsApi from "services/apis/leads.api";
import { ApplicationState } from "store";
import {
  setAllLeads,
  setLeadSearchValue,
  setViewLeadsMailModal
} from "store/leads/action";
import UpdateLeadsMailStatus from "./UpdateLeadsMailStatus";
import LeadsAction from "./leadsActions";
import LeadsFilter from "./leadsFilter";
import LeadsTableVariables from "./leadsTableVariables";

const Leads = () => {
 
  const dispatch: Dispatch<any> = useDispatch();
  const leads = useSelector((state: ApplicationState) => state.lead);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setLoaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Ilead[]>([]);
  const [allLeads, setAllLead] = useState<Ilead[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<Ilead[]>([]);
  const [viewImportModal, setViewImportModal] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [leadscount, setLeadsCount] = useState();
  const [leadMailStatusModal, setLeadMailStatusModal] = useState(false);
  const [leadsData, setLeadsData] = useState<Ilead[]>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSendMailOpen, setIsSendMailOpen] = useState<boolean>(false);

  const csvLinkRefCurrentPage = useRef<HTMLAnchorElement & CSVLink & { link: HTMLAnchorElement }>(null);

  const isFirstMount = useRef(true);

  const searchValue = useSelector(
    (state: ApplicationState) => state.lead.leadSearchValue
  );


  const initialLoadData = () => {
    dispatch(setLeadSearchValue(""));
    setLoaded(true);
    leadsApi
      .filterLeads(leadsFilter, dispatch, pageSize, pageNo)
      .then((res) => {
        setLoaded(false);
      });
  }

  const loadAllLeads = async () => {
    setLoaded(true);
    leadsApi.fetchAllLeads(dispatch).then((res) => {
      setAllLead(res);
      setLoaded(false);
    });
  };

  const loadLeadsFilter = async () => {
    if (searchValue) {
      axios
        .get(`${Constants.LEADS_SEARCH_URI}/${searchValue}`)
        .then((res) => {
          dispatch(setAllLeads(res.data));
        })
    } else {
      dispatch(setLeadSearchValue(""));
      setLoaded(true);
      leadsApi
        .filterLeads(leadsFilter, dispatch, pageSize, pageNo)
        .then((res) => {
          setLoaded(false);
          // setDateRangeLeads(res);
        });
    }
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      initialLoadData();
      loadAllLeads();
      getAllLeadsCount();
    } else {
      loadLeadsFilter();
    }

  }, [pageNo]);

  const cancelLeadData=()=>{
    dispatch(setLeadSearchValue(""));
    setLoaded(true);
    leadsApi
      .filterLeads(leadsFilter, dispatch, pageSize, pageNo)
      .then((res) => {
        setLoaded(false);
        // setDateRangeLeads(res);
      });
  }


  const leadsFilter = useSelector(
    (state: ApplicationState) => state.lead.leadsFilter
  );


  const handleselect = () => {
    dispatch(
      setViewLeadsMailModal({
        selectedRows: selectedRows,
        showModal: true,
        isModalFormSubmitting: false,
        lead: leads.allLeads,
      })
    );
  };

  const handleSelectAll = () => {
    const emailData: any = [...new Set(allLeads.map((lead) => lead.email))];
    allLeads.forEach((lead) => {
      setSelectedRows(emailData);
    });
    dispatch(
      setViewLeadsMailModal({
        selectedRows: selectedRows,
        showModal: true,
        isModalFormSubmitting: false,
        lead: allLeads,
      })
    );
  };

  const getAllLeadsCount = () => {
    axios.post(Constants.GET_LEADS_COUNT, leadsFilter).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
      setLeadsCount(res.data);
    });
  };


  const viewLeadsMailModal = useSelector(
    (state: ApplicationState) => state.lead?.viewLeadsMailModal
  );

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleLeadsMailStatusModal = (item) => {
    setLeadMailStatusModal(true);
    setLeadsData(item);
  };

  const clearSelected = () => {
    setSelectedRows([]);
    setSelectedLeads([]);
  };

  const handleSelectAllData = () => {
    const emailData: any = [...new Set(leads.allLeads.map((lead) => lead.email))];
    setSelectedRows(emailData);
    setSelectedLeads(leads.allLeads);
  }


  const handleCSVClick = () => {
    csvLinkRefCurrentPage.current?.link.click();
  };



  const LeadPageTitleBuild = () => {
    return (
      <>
        <div className="leads-title">
          <div className="page-title" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Leads</span>
            <div>
              <CButton
                className=" apply"
                size="sm"
                onClick={() => setViewImportModal(!viewImportModal)}
              >
                <div style={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }}>
                  <MdOutlineImportExport size="20px" style={{ color: "white" }} />
                  <div className="ml-1" style={{ color: "white" }}>Import</div>
                </div>
              </CButton>
            </div>
          </div>

          <div className="table-filter-section">
            <div
              style={{ display: "flex", gap: "30px", alignItems: "center" }}
            >
              <div style={{ position: "relative" }}>
                <div className="d-flex" >
                  <button
                    onClick={handleselect}
                    disabled={selectedRows.length === 0}
                    className="exportcsv"
                    style={{
                      cursor: selectedRows.length === 0 ? "not-allowed" : "pointer",
                    }}
                  >
                    Send Mail Selected
                  </button>

                  <CSVLink
                    headers={LeadsTableVariables.headers}
                    data={selectedLeads}
                    filename="CurrentPageSelectedLeadsList.csv"
                    ref={csvLinkRefCurrentPage}
                    className="hidden"
                  />
                  <button
                    className="open-button"
                    onClick={() => setIsSendMailOpen((prev) => !prev)}
                  >
                    {
                      isSendMailOpen ? <RiArrowDropUpLine style={{ width: '25px', height: "25px" }} /> : <RiArrowDropDownLine style={{ width: '25px', height: "25px" }} />
                    }
                  </button>

                </div>

                {
                  isSendMailOpen && (
                    <div className={`dropdownmenubox ${isSendMailOpen ? "show" : "hide"}`}   >
                      <button style={{
                        color: "var(--txt-primary)",
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                      }} onClick={() => setIsSendMailOpen((prev) => !prev)}>
                        {selectedRows && (
                          <button
                            onClick={() => handleSelectAll()}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "var(--txt-primary)",
                              outline: "none",
                              cursor: "pointer"
                            }}
                          >
                            Send All
                          </button>

                        )}
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>


          {/* <div>
            <CDropdown style={{ display: "flex" }}>
              <button
                onClick={handleselect}
                disabled={selectedRows.length === 0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid var(--primary, #1C63B7)",
                  borderRadius: "24px 0px 0px 24px",
                  background: "#fff",
                  width: "150px",
                  height: "40px",
                  padding: "2px 5px",
                }}
              >
                Send Mail Selected
              </button>
              <CDropdownToggle
                size="md"
                type="submit"
                className="mr-1"
                aria-hidden="true"
                style={{
                  borderRadius: "0px 25px 25px 0px",
                  borderTop: " 1px solid var(--primary, #1C63B7)",
                  borderRight: "1px solid var(--primary, #1C63B7)",
                  borderBottom: "1px solid var(--primary, #1C63B7)",
                  height: "40px",
                  marginLeft: "0 !important",
                }}
              ></CDropdownToggle>
              <CDropdownMenu>
                {selectedRows && (
                  <CDropdownItem
                    onClick={() => handleSelectAll()}
                    style={{ padding: "2px 5px" }}
                  >
                    Send All
                  </CDropdownItem>
                )}
              </CDropdownMenu>
            </CDropdown>
          </div> */}

          {/* {leads?.allLeads.length > 0 && (
              // <CButton size="sm" color="info" className="ml-1">
              //   <div className="d-flex">
              //     <i className="fa fa-download" aria-hidden="true"></i>
              //     <CSVLink
              //       headers={leadsVariables.headers}
              //       data={leads?.allLeads}
              //       filename="Leads.csv"
              //       ref={csvLinkRef}
              //       className="ml-1"
              //       style={{ textDecoration: "none", color: "#fff" }}
              //     >
              //       Export to CSV
              //     </CSVLink>
              //   </div>
              // </CButton>
              <CDropdown className="d-flex ">
                <CDropdownToggle
                  caret
                  style={{
                    backgroundColor: "#1C6387",
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                  className="ml-1"
                >
                  <i className="fa fa-download mr-1" aria-hidden="true"></i>
                  Export to CSV
                </CDropdownToggle>
                <CDropdownMenu className="mt-1">
                  <div className="m-1">
                    <MdDoNotDisturbOnTotalSilence />
                    <CSVLink
                      headers={LeadsTableVariables.headers}
                      data={allLeads}
                      filename="AllLeads.csv"
                      ref={csvLinkRef}
                      className="ml-1"
                      style={{
                        textDecoration: "none",
                        color: "#5d6c77",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      Export All
                    </CSVLink>
                  </div>
                  <div className="m-1">
                    <BiSelectMultiple />
                    <CSVLink
                      headers={leadsVariables.headers}
                      data={leads.allLeads}
                      filename="CurrentPageLeads.csv"
                      ref={csvLinkRef}
                      className="ml-1"
                      style={{
                        textDecoration: "none",
                        color: "#5d6c77",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      Export Current Page
                    </CSVLink>{" "}
                  </div>
                  <div className="m-1">
                    <BiSelectMultiple />
                    <CSVLink
                      headers={leadsVariables.headers}
                      data={selectedLeads}
                      filename="SelectedLeads.csv"
                      ref={csvLinkRef}
                      className="ml-1"
                      style={{
                        textDecoration: "none",
                        color: "#5d6c77",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                      aria-disabled
                    >
                      Export Selected
                    </CSVLink>{" "}
                  </div>
                </CDropdownMenu>
              </CDropdown>
            )} */}
        </div>

        {/* <small className="py-2">
          <strong>
            Total Leads : {totalData.totalLeads}
            &nbsp;|&nbsp;Subscribed Leads : {totalData.totalSubscribedLeads}
          </strong>
        </small> */}
      </>
    );
  };


  const LeadsTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  padding: "12px",
                  backgroundColor: "#fff",
                }}
              >
                <TMFSearch
                  sapi={Constants.LEADS_SEARCH_URI}
                  dispatch={dispatch}
                  isSearchActive={(e) => setIsSearchActive(e)}
                  searchIn="leads"
                  // onCalcelSearch={() => loadAllLeads()}
                  onCalcelSearch={() => cancelLeadData()}

                />
                <div style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                  {/* {selectedRows.length !== 0 && ( */}

                  <button
                    className="clear"
                    onClick={clearSelected}
                    disabled={selectedRows.length === 0}
                  >
                    Clear Selected
                  </button>

                  <CButton
                    className="apply"
                    size="sm"
                    onClick={handleSelectAllData}
                  >
                    <div className="d-flex">
                      <BiSelectMultiple size="20px" style={{ color: "white" }} />
                      <div className="ml-1" style={{ color: "white" }}>Select All</div>
                    </div>
                  </CButton>
                </div>
              </div>

              <div className="table-filter-section">
                <div
                  style={{ display: "flex", gap: "30px", alignItems: "center" }}>

                  <div style={{ position: "relative" }}>
                    <div className="d-flex" >
                      <button
                        onClick={handleCSVClick}
                        disabled={selectedRows.length === 0}
                        className="exportcsv"
                        style={{
                          cursor: selectedRows.length === 0 ? "not-allowed" : "pointer",
                        }}
                      >
                        Export Selected
                      </button>
                      <CSVLink
                        headers={LeadsTableVariables.headers}
                        data={selectedLeads}
                        filename="CurrentPageSelectedLeadsList.csv"
                        ref={csvLinkRefCurrentPage}
                        className="hidden"
                      />
                      <button
                        className="open-button"
                        onClick={() => setIsOpen((prev) => !prev)}
                      >
                        {
                          isOpen ? <RiArrowDropUpLine style={{ width: '25px', height: "25px" }} /> : <RiArrowDropDownLine style={{ width: '25px', height: "25px" }} />
                        }
                      </button>

                    </div>

                    {
                      isOpen && (
                        <div className={`dropdownmenubox ${isOpen ? "show" : "hide"}`}   >
                          <button style={{ color: "var(--txt-primary)", background: "none", border: "none" }} onClick={() => setIsOpen((prev) => !prev)}>
                            <CSVLink
                              headers={LeadsTableVariables.headers}
                              data={allLeads}
                              filename="AllLeads.csv"
                              ref={csvLinkRef}
                              // className="hidden"
                              style={{ color: "var(--txt-primary)" }}
                            >
                              Export All
                            </CSVLink>
                          </button>
                        </div>
                      )
                    }
                  </div>

                  {
                    !isSearchActive &&
                    <span style={{ marginLeft: "auto" }}>
                      <DTablePagination
                        cPage={pageNo}
                        tPage={totalPage!}
                        onPageChange={(e) => {
                          setPageNo(e);
                          loadAllLeads();
                        }}
                      ></DTablePagination>
                    </span>

                  }

                </div>
              </div>


            </div>

            <div style={{ zIndex: "0" }}>
              <CDataTable
                items={leads?.allLeads}
                fields={LeadsTableVariables.fields}
                // tableFilter={{ placeholder: "search leads" }}
                responsive={true}
                loading={isLoading}
                noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
                hover
                // sorter
                scopedSlots={{
                  id: (item: any) => (
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.email)}
                        onChange={() => {
                          if (selectedRows.includes(item.email)) {
                            setSelectedRows(
                              selectedRows.filter((email) => email !== item.email)
                            );
                            setSelectedLeads(
                              selectedLeads.filter((lead) => {

                                return lead.email !== item.email;
                              })
                            )

                          } else {
                            setSelectedRows([...selectedRows, item.email]);
                            setSelectedLeads([...selectedLeads, item]);
                          }
                        }}
                      />
                    </td>
                  ),
                  firstName: (item: any) => (
                    <td>
                      <div className="name">
                        <span
                          // className="table-column-item"
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: "500",
                            color: "var(--txt-primary, #23005B)",
                          }}
                        >
                          {item.firstName || item.lastName
                            ? `${item.firstName
                              ? item.firstName.charAt(0).toUpperCase() +
                              item.firstName.slice(1).toLowerCase()
                              : ""
                              } ${item.lastName
                                ? item.lastName.charAt(0).toUpperCase() +
                                item.lastName.slice(1).toLowerCase()
                                : ""
                              }`.trim()
                            : "N/A"}
                        </span>
                      </div>
                    </td>
                  ),
                  phone: (item: any) => (
                    <td>

                      <div className="detail">
                        <span
                          style={{
                            color: "var(--txt-primary, #23005B)",
                          }}
                        >
                          {item.email ? item.email : "..."}
                        </span>

                        <span style={{ paddingTop: "6px" }} >{item.phone ? item.phone : "..."}</span>
                      </div>


                    </td>
                  ),
                  // actionCode: (item: any) => (
                  //   <td>{`${item.actionCode ? item.actionCode : ""}`}</td>
                  // ),

                  mailStatus: (item: any) => (
                    <>
                      {/* <button
                  className="btn-info btn-sm mt-1"
                  onClick={() => {
                    handleLeadsMailStatusModal(item);
                  }}
                >
                  {" "}
                  View
                </button> */}
                      <td>
                        <CButton
                          className="mt-1"
                          color="info"
                          size="sm"
                          style={{ alignContent: "center", background: "var(--primary)", borderColor: "var(--primary)" }}
                          onClick={() => {
                            handleLeadsMailStatusModal(item);
                          }}
                        >
                          {" "}
                          View
                        </CButton>
                      </td>
                    </>
                  ),
                  visitDate: (item: any) => (
                    <td>{moment(item.visitDate).format("MMM DD YYYY")}</td>
                  ),
                  isSubscribed: (item) => (
                    <td>
                      {item.isSubscribed === true ? (
                        <CButton color="success" size="sm">
                          Yes
                        </CButton>
                      ) : (
                        // <button type="button" className="btn btn-info btn-sm">
                        //   Yes
                        // </button>
                        <CButton color="danger" size="sm">
                          No
                        </CButton>
                      )}
                    </td>
                  ),
                  Action: (item) => {
                    return (
                      <td>
                        <LeadsAction lead={item}></LeadsAction>
                      </td>
                    );
                  },
                }}
              />
            </div>

            {!isSearchActive && (
              <DTablePagination
                tPage={totalPage!}
                cPage={pageNo}
                onPageChange={(e) => setPageNo(e)}
              />
            )}

          </div>

        </div>
      </div>

    );
  };


  const LeadsMailPage = () => {
    return (
      <>
        {viewLeadsMailModal?.showModal && (
          <LeadsMailModal
            selectedRows={selectedRows}
            lead={viewLeadsMailModal?.lead}
            isOpen={viewLeadsMailModal?.showModal}
            onClose={() =>
              dispatch(setViewLeadsMailModal({ showModal: false }))
            }
          />
        )}
      </>
    );
  };


  return (
    <>
      <LeadPageTitleBuild></LeadPageTitleBuild>

      <LeadsFilter
        cPage={pageNo}
        onPageChange={(e) => {
          setPageNo(e);
          getAllLeadsCount();
        }}
      ></LeadsFilter>

      <LeadsTableBuild></LeadsTableBuild>


      <LeadsMailPage></LeadsMailPage>

      {viewImportModal && (
        <ImportLeads
          onClose={() => setViewImportModal(false)}
          isOpen={viewImportModal}
          refreshLeads={() => {
            loadLeadsFilter();
          }}
        />
      )}

      {leadMailStatusModal && (
        <UpdateLeadsMailStatus
          leadsData={leadsData}
          refreshDetails={() => {
            loadAllLeads();
          }}
          isOpen={leadMailStatusModal}
          onClose={() => {
            setLeadMailStatusModal(false);
          }}
        />
      )}
    </>
  );
};

export default Leads;
