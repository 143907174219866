import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../../constants/Constants";
import { Bounce, toast } from "react-toastify";

const UndoDeleteOrganizationModal = ({ orgDetails, refreshDetails, isOpen, onClose }) => {
  const [isSpinning, setSpinning] = useState(false);

  const undoDeleteOrganization = async () => {
    setSpinning(true);
    await api
      .post(Constants.ORGANIZATION_UNDO_DELETE_URI + "/" + orgDetails.organizationId+"/"+orgDetails.email)
      .then(async (res) => {
        setSpinning(false);
        await onClose();
        toast.success(` Organization Undo Successfully`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        refreshDetails();
      }).catch(async (err) => {
        toast.error(` ${err.response}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
      })
      .finally(() => {
        setSpinning(false);
      });
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div>Do you want to restore this Organization?</div>

          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="danger" onClick={() => undoDeleteOrganization()} size="sm">
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="primary" disabled size="sm">
                <i className="fa fa-spinner fa-spin"></i> Undeleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default UndoDeleteOrganizationModal;
