import DataFilter from "components/common/dataFilter";
import { useEffect, useRef, useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";


interface ActivtiyFilterProps {
    activities: IActivityModel,
    setActivities: React.Dispatch<React.SetStateAction<IActivityModel>>
    setPageNo: React.Dispatch<React.SetStateAction<number>>
    loadFilterData: () => void
    setShouldLoadData: React.Dispatch<React.SetStateAction<boolean>>
}

const ActivityFilter = ({ setPageNo, activities, setActivities, loadFilterData, setShouldLoadData }: ActivtiyFilterProps) => {

    const [showFields, setShowFields] = useState(false);
    const [firmData, setFirmData] = useState<any>([]);

    const [openSelects, setOpenSelects] = useState({
        source: false,
        appType: false,
        activityType: false,
        firmID: false,
        isActive: false,
        ActivityStatus:false
    });

    const dateRangeRef = useRef<HTMLDivElement | null>(null);

    const allOrganizations = useSelector(
        (state: ApplicationState) => state.organization.allOrganizations
    );

    const selectDropdownSearch = (data) => {
        setFirmData(
            data.map((item) => {
                return { label: item.name, value: item.organizationID };
            })
        );
    };



    useEffect(() => {
        selectDropdownSearch(allOrganizations);
    }, [allOrganizations]);


    const handleSelectClick = (selectName) => {
        setOpenSelects(prev => ({
            ...prev,
            [selectName]: !prev[selectName]
        }));
    };



    function handleChange(
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
    ) {

        const selectedValue = e.target.value;

        if (selectedValue == "Select Date Range") {

            setActivities({
                ...activities,
                startDate: null,
                endDate: null,
            })

            return;
        }
        let startDate;
        let endDate = new Date();

        if (selectedValue.includes("Last")) {
            switch (selectedValue) {
                case "Last 30 Days":
                    startDate = new Date();
                    startDate.setDate(endDate.getDate() - 30);
                    break;
                case "Last 60 Days":
                    startDate = new Date();
                    startDate.setDate(endDate.getDate() - 60);
                    break;
                case "Last 90 Days":
                    startDate = new Date();
                    startDate.setDate(endDate.getDate() - 90);
                    break;
                case "Last Week":
                    startDate = new Date();
                    startDate.setDate(endDate.getDate() - 7);
                    break;
            }

            setActivities({
                ...activities,
                startDate: startDate.toISOString().split("T")[0],
                endDate: endDate.toISOString().split("T")[0],
            })
        } else if (e.target.name === "isActive") {
            setActivities({
                ...activities,
                [e.target.name]: e.target.value?.length ? e.target.value === 'true' : null,
            })
        } else {
            setActivities({
                ...activities,
                [e.target.name]: e.target.value?.length ? e.target.value : null,
            })

        }
    }

    const handleClearFilter = (clearAll = true) => {
        activities.startDate = null;
        activities.endDate = null;

        if (clearAll) {
            setActivities({
                firmID: null,
                startDate: null,
                endDate: null,
                activityBy: null,
                appType: null,
                activityType: null,
                source: null,
                userTypeId: null,
                isActive: null,
                ActivityStatus:null
            });
   
            setShouldLoadData(true);
            setShowFields(false);
        }
    };

    const handleClicks = () => {
        setShowFields(!showFields);
    };


    useEffect(() => {
        if (activities.startDate !== null && activities.endDate === null) {
            return;
        } else {
            const handleClickOutside = (event: MouseEvent) => {
                if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
                    if (dateRangeRef.current) {
                        setShowFields(false)
                    }
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [activities.startDate, activities.endDate]);


    return (

        <DataFilter>
            <div className="filter">
                <div>
                    <label htmlFor="firmID">Organization Name</label>
                    <div className="select-wrapper">
                        <select
                            name="firmID"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            value={`${activities.firmID}`}
                            onClick={() => handleSelectClick('firmID')}
                            onBlur={() => setOpenSelects(prev => ({ ...prev, firmID: false }))}
                        >
                            <option value="">Select Organization</option>
                            { firmData?.map((item, index) => (
                                <option value={item.value} key={index}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        {openSelects.firmID ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                    </div>
                </div>
                <div>
                    <label htmlFor="source">Source</label>
                    <div className="select-wrapper">
                        <select
                            name="source"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            value={`${activities.source}`}
                            onClick={() => handleSelectClick('source')}
                            onBlur={() => setOpenSelects(prev => ({ ...prev, source: false }))}
                        >
                            <option value="">Select Source Type</option>
                            <option value="web">Web</option>
                            <option value="teams">Teams</option>
                            <option value="outlook">Outlook</option>
                            <option value="word">Word</option>
                        </select>
                        {openSelects.source ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                    </div>

                </div>

                <div>
                    <label htmlFor="appType">App Type</label>
                    <div className="select-wrapper">
                        <select
                            name="appType"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            value={`${activities.appType}`}
                            onClick={() => handleSelectClick('appType')}
                            onBlur={() => setOpenSelects(prev => ({ ...prev, appType: false }))}
                        >
                            <option value="">Select App Type</option>
                            <option value="webapp">Web App</option>
                            <option value="adminportal">Admin Portal</option>
                            <option value="superadmin">SuperAdmin Portal</option>
                        </select>
                        {openSelects.appType ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                    </div>

                </div>
                <div>
                    <label htmlFor="activityType">Activity Type</label>
                    <div className="select-wrapper">
                        <select
                            name="activityType"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            value={`${activities.activityType}`}
                            onClick={() => handleSelectClick('activityType')}
                            onBlur={() => setOpenSelects(prev => ({ ...prev, activityType: false }))}
                        >
                            <option value="">Select Activity Type</option>
                            <option value="login">Login</option>
                            <option value="logout">Logout</option>
                        </select>
                        {openSelects.activityType ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                    </div>
                </div>
                <div>
                    <label htmlFor="isActive">Status Filter</label>
                    <div className="select-wrapper">
                        <select
                            name="ActivityStatus"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            value={`${activities.ActivityStatus}`}
                            onClick={() => handleSelectClick('ActivityStatus')}
                            onBlur={() => setOpenSelects(prev => ({ ...prev, ActivityStatus: false }))}
                        >
                            <option value="">Select Activity Status</option>
                            <option value="success">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="unauthorized">Unauthorized</option>
                            <option value="expired">Expired</option>
                            <option value="notfound">Unregistered</option>
                        </select>
                        {openSelects.ActivityStatus ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                    </div>
                </div>

                <div className="relative" >
                    <label htmlFor="dateRange">Date Range</label>

                    <div className="date-range">
                        <input
                            id="dateRange"
                            readOnly
                            className="form-control"
                            placeholder={`${activities.startDate ? activities.startDate : "Select Date Range"} ${activities.endDate ? "-" : ""} ${activities.endDate ? activities.endDate : ""}`}
                        ></input>
                        {
                            showFields ? <RiArrowDropUpLine onClick={handleClicks} /> : <RiArrowDropDownLine onClick={handleClicks} />
                        }
                    </div>

                    {showFields && (
                        <div ref={dateRangeRef} className={`dateRangeRef ${showFields ? 'show' : 'hide'}`}>
                            <div className="flex" style={{ gap: "0.5rem", alignItems: "stretch", flexWrap: "wrap" }}>
                                <fieldset>
                                    <div className="tmf-form-group">
                                        <label htmlFor="dateRanges">Date Ranges</label>
                                        <select
                                            id="dateRanges"
                                            className="form-control"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <option value="">Select Date Range</option>
                                            <option value="Last 30 Days">Last 30 Days</option>
                                            <option value="Last 60 Days">Last 60 Days</option>
                                            <option value="Last 90 Days">Last 90 Days</option>
                                            <option value="Last Week">Last Week</option>
                                        </select>
                                    </div>

                                </fieldset>
                                <fieldset>
                                    <div className="tmf-form-group">
                                        <label>Start Date</label>
                                        <input
                                            placeholder="date"
                                            className="form-control sm"
                                            type="date"
                                            name="BeginDate"
                                            value={`${activities.startDate}`}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    {activities.startDate ? (
                                        <div className="tmf-form-group">
                                            <label>End Date</label>
                                            <input
                                                placeholder="date"
                                                className="form-control"
                                                type="date"
                                                value={`${activities.endDate}`}
                                                name="EndDate"
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                min={activities.startDate as string}
                                            />

                                            <small className="text-danger">
                                                {activities.startDate != null && activities.endDate === null ? "End Date is required" : ""}
                                            </small>
                                        </div>
                                    ) : (
                                        <div className="tmf-form-group">
                                            <label>End Date</label>
                                            <input disabled className="form-control" type="date" value={`${activities.endDate}`} />
                                        </div>
                                    )}
                                </fieldset>
                            </div>

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                <div className="tmf-filter__submit">
                                    <button
                                        className="clear"
                                        type="reset"
                                        onClick={() => handleClearFilter()}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <button
                    onClick={() => loadFilterData()}
                    className="apply"
                    type="submit"
                >
                    Apply
                </button>

                <button
                    onClick={() => {
                        handleClearFilter(true);
                    }}
                    className="clear"
                    type="reset"
                >
                    Clear
                </button>


            </div>
        </DataFilter>
    );
};

export default ActivityFilter;
