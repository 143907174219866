import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

const AddUserModal = ({ refreshDetails, isOpen, onClose }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue
  } = useForm();
  const [user, setUser] = useState({});
  const [time, setTimeZone] = useState([]);
  const [isSpinning, setSpinning] = useState(false);
  const [isloading, setLoaded] = useState(false);
  const [dropdownOrganization, setDropDownOrganization] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [isEmailExist, setIsEmailExist] = useState<boolean>(false);


  useEffect(() => {
    getAllOrganization();
    loadTimeZone();
    getAllUserTypes();
  }, []);

  const onInputChange = (e) => {
    let email = e.target.value;
    if (e.target.value.trim() !== '') {
      api.post(Constants.CHECK_USER_EXIST, { email: email })
        .then(async (response) => {
          setIsEmailExist(response.data);
          setValue("email", e.target.value);
        }).catch((error) => {
          console.log(error);
        })
    }
  };

  const getAllUserTypes = () => {
    api.get(Constants.USERTYPES_GET_URI).then((res) => {
      setUserTypes(res.data);
    });
  };

  const getAllOrganization = () => {
    //setLoaded(true);
    api
      .getWithParams(Constants.ORGANIZATION_GET_URI, dropdownOrganization!)
      .then((res) => setDropDownOrganization(res.data))
      .finally(() => setLoaded(false));
  };




  const organizationDropDown = dropdownOrganization?.map((item: IOrganizationType) => {
    return {
      label: item.name,
      value: item.organizationID,
    };
  });

  const userTypeLabel = userTypes?.map((item: any) => {
    return {
      label: item.type,
      value: item.id,
    };
  });

  const timeZone = time?.map((item: any) => {
    return {
      label: item.displayName,
      value: item.id,
    };
  });

  const onTimeZoneInputChange = (e) => {
    setValue("timezone", Number(e.target.value));
  }

  const onOrganizationInputChange = (e) => {
    setValue("organizationID", Number(e.target.value));
  }

  const onuserTypeIDInputChange = (e) => {
    setValue("userTypeId", Number(e.target.value));
  }


  const onSubmit = (data) => {

    // setSpinning(true);
    // api
    //   .post(
    //     Constants.USER_CHECK_EXISTS_URI +
    //     "/" +
    //     data.email +
    //     "/" +
    //     data.organizationID
    //   )
    //   .then((res) => {
    //     if (res.data) {
    //       ////console.log(`This user is already part of the organization!`)
    //       toast.warn("User already part of an organization!", {
    //         position: "bottom-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //         transition: Bounce,
    //       });
    //       setSpinning(false);

    setSpinning(true);
    api
      .post(Constants.USER_CREATE_POST_URI, data)
      .then(async () => {
        toast.success("😊 User added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        refreshDetails();
        setUser({
          title: "",
          first_Name: "",
          middle_Name: "",
          last_Name: "",
          email: "",
          phone: "",
          timezone: "",
        });
      })
      .catch((err) => {
        toast.error(`😔 ${err.response}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => {
        setSpinning(false);
      });

    // });
  };

  const loadTimeZone = () =>
    api.get(Constants.TIMEZONE_GET_URI).then((res) => setTimeZone(res.data));

  const TitleField = register("title", {
    required: true,
  });

  const FirstNameField = register("first_Name", {
    required: true,
  });

  const LastNameField = register("last_Name", {
    required: true,
  });


  const PhoneNumberField = register("phone", {
    required: true,
  });



  return (
    <div>
      {!isloading && time && dropdownOrganization && userTypes && (
        <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
          <CModalHeader closeButton>
            <CModalTitle>
              {" "}
              <div> Add New User</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Title*</label>
                    <select
                      {...TitleField}
                      className="form-control"
                    >
                      <option></option>
                      <option>Ms.</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                    </select>
                    <small className="text-danger">
                      {errors.title?.type === "required" && "Title is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>First Name*</label>
                    <input
                      {...FirstNameField}
                      type="text"
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.first_Name?.type === "required" &&
                        "First Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Middle Name</label>
                    <input
                      {...register("middle_Name")}
                      type="text"
                      placeholder="Optional"
                      className="form-control"
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Last Name*</label>
                    <input
                      {...LastNameField}
                      type="text"
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.last_Name?.type === "required" &&
                        "Last Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      type="email"
                      className="form-control"
                      {...(register("email", { required: true }))}
                      onBlur={(e) => onInputChange(e)}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                      {isEmailExist ? "Email already exists" : null}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number*</label>
                    <input
                      {...PhoneNumberField}
                      type="text"
                      className="form-control "

                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is Required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Timezone*</label>

                    <Controller
                      name="timezone"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onTimeZoneInputChange(e);
                          }}
                          className="form-control"
                        >
                          <option></option>
                          {
                            timeZone?.map((item, index) => (
                              <React.Fragment key={index}>
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              </React.Fragment >
                            ))
                          }
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Organization*</label>


                    <Controller
                      name="organizationID"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onOrganizationInputChange(e);
                          }}

                          className="form-control"
                        >
                          <option></option>
                          {
                            organizationDropDown?.map((item, index) => (
                              <React.Fragment key={index}>
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              </React.Fragment >
                            ))
                          }
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.organizationID?.type === "required" &&
                        "Organization is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>

                    <label>UserType*</label>
                    <Controller
                      name="userTypeID"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onuserTypeIDInputChange(e);
                          }}

                          className="form-control"
                        >
                          <option></option>
                          {
                            userTypeLabel?.map((item, index) => (
                              <React.Fragment key={index}>
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              </React.Fragment >
                            ))
                          }
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.userTypeID?.type === "required" &&
                        "User Type is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol></CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Description</label>
                    <textarea
                      placeholder="Optional"
                      {...register("description")}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>

              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    style={{ background: "var(--primary)", color: "white" }}
                    type="submit"
                    size="sm"
                    disabled={isEmailExist}
                  >
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}

              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    disabled
                    style={{ background: "var(--primary)", color: "white" }}
                    size="sm"
                  >
                    <i className="fa fa-spinner fa-spin"></i> Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}

            </form>
          </CModalBody>
        </CModal>
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default AddUserModal;
