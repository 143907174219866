import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import { IoIosAddCircle } from "react-icons/io";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";
import planApi from "services/apis/plan.api";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { GetPlanById } from "store/plan/action";
import { IoIosAddCircle } from "react-icons/io";
import { CgDanger } from "react-icons/cg";

interface IEditSubscriptionModal {
  refreshDetails: () => void,
  id: number | undefined,
  orgID: number | undefined,
  isOpen: boolean,
  onClose: any,
}

const EditSubscriptionModal = ({ refreshDetails, id, isOpen, onClose, orgID }: IEditSubscriptionModal) => {


  const dispatch: Dispatch<any> = useDispatch();

  const planData = useSelector(
    (state: ApplicationState) => state.plan
  );

  const [subscriptionModal, setSubscription] = useState<ISubscription | null>(null);
  const [plan, setPlan] = useState([]);
  const [isloading, setLoaded] = useState(false);
  const [isSpinning, setSpinning] = useState(false);
  // const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [increaseSubs, setIncreasedValue] = useState(null);
  // const [preloadedValues, setPreloadedValues] = useState(null);
  // const [isPurchasedLicensesDisabled, setIsPurchasedLicensesDisabled] =
  //   useState(false);
  const [orgIsAICopilotActive, setOrgIsAICopilotActive] = useState<boolean>(false);
  const [orgDataLoading, setOrgDataLoading] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm();

  useEffect(() => {
    loadPlan();
    //loadSubscriptionTypes();
    loadSubscription();
    getOrganizationAICopilotData();
    // increaseSubscription();
  }, []);

  const currentDate = new Date();
  const formattedcurrentDate = moment(currentDate).format("YYYY-MM-DD")

  const getOrganizationAICopilotData = () => {
    setOrgDataLoading(true);
    api.post(Constants.ORGANIZATION_EDIT_URI + "/" + orgID)
      .then((res) => {
        setOrgIsAICopilotActive(res.data.aiCopilot);
      })
      .finally(() => setOrgDataLoading(false));
  };



  const loadSubscription = () => {
    setLoaded(true);
    api
      .post(Constants.SUBSCRIPTION_EDIT_URI + "/" + id)
      .then((res) => {
        // const values = {
        //   purchasedLicenses: res.data.purchasedLicenses,
        //   planName: res.data.planName,
        //   planID: res.data.planID,
        //   subscriptionType: res.data.subscriptionType,
        //   subscriptionTypeID: res.data.subscriptionTypeID
        // };
        // setPreloadedValues(values);
        setSubscription(res.data);
        // setIncreasedValue(res.data.endDate ? res.data.endDate : "");
      })
      .finally(() => setLoaded(false));
  };

  const loadPlan = () => api.get(Constants.PLAN_GET_URI).then((res) => setPlan(res.data));


  const planPurchased = [
    {
      id: 1,
      label: "Purchased",
      value: true,
    },
    {
      id: 2,
      label: "Not-Purchased",
      value: false
    }
  ]

  const planDropDown = plan.map((item: Iplan) => {
    return {
      label: item.name,
      value: item.id,
      perLicenceCost: item.perLicenceCost,
    };
  });



  const changeRenew = (e: any) => {
    setValue("autoRenew", e.target.checked);
  }

  const onPlanInputChange = (e) => {
    // let event = e.target;
    // let plc = planDropDown.filter((item) => item.value == event.value)[0]
    //   .perLicenceCost;
    // setSubscription({
    //   ...subscriptionModal,
    //   planID: event.value,
    //   perLicenseCost: plc,
    // });
    setValue("planID", Number(e.target.value));
    planApi.getPlanById(dispatch, Number(e.target.value));
  };

  // const onInputChange = (e) => {
  //   // setSubscription({ ...subscriptionModal, [e.target.name]: e.target.value });
  // };

  const onPlanPurchasedChange = (e) => {
    setValue("IsPurchased", e.target.value);
  }

  const onCloseClick = () => {
    dispatch(GetPlanById(undefined));
    onClose();
  }





  // const loadSubscriptionTypes = () =>
  //   api
  //     .get(Constants.SUBSCRIPTION_TYPES_GET_ALL_URI)
  //     .then((res) => setSubscriptionTypes(res.data));

  const purchasedLicensesField = register("purchasedLicenses", {
    required: false,
  });
  // const planField = register("planID", {
  //   required: false,
  // });
  // const SubscriptionTypeField = register("subscriptionTypeID", {
  //   required: false,
  // });

  // const expandSubscription = register ("EndDate", {
  //   required:false,
  // });
  // //console.log("subscription ", subscriptionModal);

  const increaseSubscription = () => {
    const endDate = watch("endDate")
    var date = new Date(endDate);
    date.setMonth(date.getMonth() + 1);
    const formattedDate = date.toISOString().split("T")[0];
    setValue("endDate", formattedDate);
  };


  const onSubmit = (data) => {
    setSpinning(true);

    const currentDateUTC = new Date().toUTCString();
    const updatedSubscriptionModal = {
      ...data,
      IsPurchased: data.IsPurchased === "true"
    };

    if (data.IsPurchased === "true") {
      if (new Date(updatedSubscriptionModal.endDate) < new Date(currentDateUTC)) {
        updatedSubscriptionModal.hasExpired = true;
      } else {
        updatedSubscriptionModal.hasExpired = false;
      }
    }

  

    api
      .post(
        Constants.SUBSCRIPTION_UPDATE_POST_URI + "/" + id,
        updatedSubscriptionModal
      )
      .then(async (response) => {
        toast.success("😊 Subscription edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        refreshDetails();
        dispatch(GetPlanById(undefined));
      })
      .catch((error) => {
        toast.error(`😔 Error while editing subscription!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => {
        setSpinning(false);
      });
  };


  useEffect(() => {
    if (subscriptionModal) {


      setValue("planID", subscriptionModal.planID);
      setValue("OrgID", subscriptionModal.orgID);
      setValue("purchasedLicenses", subscriptionModal.purchasedLicenses);
      setValue("autoRenew", subscriptionModal.autoRenew);
      setValue("allowedNumberOfBookmarks", subscriptionModal.allowedNumberOfBookmarks);
      setValue("IsPurchased", subscriptionModal.isPurchased.toString());
      setValue("perLicenseCost", subscriptionModal.perLicenseCost)
      setValue("subscriptionTypeID", subscriptionModal.subscriptionTypeID);
      setValue("subscriptionType", subscriptionModal.subscriptionType);
      setValue("allowedNoOfAICopilotUser", subscriptionModal.allowedNoOfAICopilotUser);
      setValue("subscriptionType", subscriptionModal.subscriptionType);
      setValue("isFreePlan",subscriptionModal.isFreePlan);

      const formattedStartDate = moment(subscriptionModal.startDate).format("YYYY-MM-DD");
      setValue("startDate", formattedStartDate);
      const formattedEndDate = moment(subscriptionModal.endDate).format("YYYY-MM-DD");
      setValue("endDate", formattedEndDate);
    }
  }, [subscriptionModal])

  useEffect(() => {
    if (planData.plan) {
      setValue("purchasedLicenses", planData.plan.minimumLicenceCount)
      setValue("perLicenseCost", planData.plan.perLicenceCost)
      setValue("allowedNumberOfBookmarks", planData.plan.allowedNumberOfBookmarks)
      setValue("subscriptionTypeID", planData.plan.subscriptionTypeId);
      setValue("subscriptionType", planData.plan.subscriptionType);

      // if (planData.plan.subscriptionType.toLowerCase() === "annual") {
      //   const currentDate = new Date();
      //   const endDate = new Date(currentDate);
      //   endDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);
      //   const formattedEndDate = endDate.toISOString().split("T")[0];
      //   setValue("endDate", formattedEndDate);
      // }


    }
  }, [planData.plan]);


  return (
    <div>
      {!isloading && subscriptionModal && (
        <CModal show={isOpen} onClose={onCloseClick} style={{ padding: "4px" }}>
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Edit Subscription</div>{" "}
            </CModalTitle>
          </CModalHeader>

          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            <CModalBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Start Date</label>
                    <input

                      required
                      {...register("startDate", {
                        required: true
                      })}
                      type="date"
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.startDate?.type === "required" &&
                        " Start Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <div style={{
                      display: 'flex',
                      alignItems: " center",
                      justifyContent: "space-between",
                      padding: " 0 10px"
                    }}>
                      <label>End Date</label>
                      <div style={{
                        position: 'relative',
                        display: 'inline-block'
                      }}>
                        {
                          planData.plan != undefined && (
                            <CgDanger
                              style={{
                                color: '#FF9800',
                                cursor: 'pointer',
                                fontSize: '16px',
                                verticalAlign: 'middle'
                              }}
                              onMouseEnter={() => setShowTooltip(true)}
                              onMouseLeave={() => setShowTooltip(false)}
                            />
                          )
                        }
                        {showTooltip && (
                          <div style={{
                            position: 'absolute',
                            left: '100%',
                            marginLeft: '8px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgb(230 228 228)',
                            color: 'white',
                            padding: '8px 12px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            width: '200px',
                            zIndex: 1000,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            lineHeight: '1.4'
                          }}>
                            <small style={{
                              fontSize: '10px'
                            }} className="text-muted">
                              Please note that the plan for this organization has been updated. Kindly verify the end date to ensure accuracy.
                              <hr />
                              Current Date : <b>{formattedcurrentDate} </b>
                              <br />
                              Last Plan End Date :  <b>{moment(subscriptionModal.endDate).format("YYYY-MM-DD")}</b>
                            </small>

                            <div style={{
                              position: 'absolute',
                              left: '-6px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              width: '0',
                              height: '0',
                              borderTop: '6px solid transparent',
                              borderBottom: '6px solid transparent',
                              borderRight: '6px solid rgb(230 228 228)',

                            }} />
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      required

                      {...register("endDate", {
                        required: true
                      })}
                      type="date"
                      className="form-control"
                    />

                    <small className="text-danger">
                      {errors.endDate?.type === "required" &&
                        " End Date is required"}
                    </small>
                    <label>Increase subscription</label>
                    <IoIosAddCircle
                      size="25px"
                      onClick={increaseSubscription}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>


              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Plan *</label>
                    <Controller
                      name="planID"
                      control={control}
                      // defaultValue={subscriptionModal.isPurchased || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onPlanInputChange(e);
                          }}
                          className="form-control"
                          value={field.value}
                        >

                          {
                            planDropDown.map((item, index) => (
                              <option
                                key={index}
                                value={item.value}
                              // selected={subscriptionModal.isPurchased === item.value}
                              >
                                {item.label}
                              </option>
                            ))
                          }
                        </select>
                      )}
                    />
                    {/* <select
                      {...planField}
                      onChange={(e) => {
                        planField.onChange(e);
                        onPlanInputChange(e);
                      }}
                      className="form-control"

                    >
                      {planDropDown.map((temp, index) => {
                        return (
                          <option
                            value={temp.value}
                            key={index}
                            selected={subscriptionModal.planID === temp.value}
                          >
                            {temp.label}
                          </option>
                        );
                      })}
                    </select> */}
                    <small className="text-danger">
                      {errors.planID?.type === "required" && "Plan is required"}
                    </small>
                  </CFormGroup>
                </CCol>

                <CCol>
                  <CFormGroup >
                    <label>Plan Purchase*</label>

                    <Controller
                      name="IsPurchased"
                      control={control}
                      // defaultValue={subscriptionModal.isPurchased || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onPlanPurchasedChange(e);
                          }}

                          className="form-control"
                          value={field.value}
                        >

                          {
                            planPurchased?.map((item, index) => (

                              <option
                                key={index}
                                value={item.value.toString()}
                              // selected={subscriptionModal.isPurchased === item.value}
                              >
                                {item.label}
                              </option>

                            ))
                          }
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.IsPurchased?.type === "required" &&
                        "Plan Purchase is required"}
                    </small>
                  </CFormGroup>


                </CCol>
              </CRow>


              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>User Licenses*</label>
                    {
                      planData.plan === undefined ? (
                        subscriptionModal !== undefined ? (
                          subscriptionModal?.purchasedLicenses < 0 ? (
                            <input
                              disabled
                              type="text"
                              defaultValue={"Unlimited"}
                              className="form-control"
                            />
                          ) : (
                            <input
                              type="number"
                              {...purchasedLicensesField} // Spread the existing field values
                              max={subscriptionModal?.purchasedLicenses}
                              min={0}
                              className="form-control"
                            // disabled={isPurchasedLicensesDisabled} // Uncomment this line if you want to conditionally disable
                            />
                          )
                        ) : (
                          <input
                            disabled
                            type="text"
                            className="form-control"
                          />
                        )
                      ) : (
                        planData.plan?.minimumLicenceCount < 0 ? (
                          <input
                            disabled
                            type="text"
                            defaultValue={"Unlimited"}
                            value={"Unlimited"}
                            className="form-control"
                          />

                        ) : (
                          <input
                            type="number"
                            {...purchasedLicensesField}
                            max={planData.plan?.minimumLicenceCount}
                            min={0}
                            className="form-control"

                          />
                        )
                      )
                    }

                    <small className="text-danger">
                      {errors.purchasedLicenses?.type === "required" &&
                        "License is required"}
                      {errors.purchasedLicenses?.type === "min" && "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>

                <CCol>
                  <label>Per License Cost</label>
                  <input
                    {...register("perLicenseCost")}
                    className="form-control"
                    disabled
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Bookmark Licenses*</label>
                    {
                      planData.plan === undefined ? (
                        subscriptionModal !== undefined ? (
                          subscriptionModal.allowedNumberOfBookmarks < 0 ? (
                            <input
                              disabled
                              type="text"
                              defaultValue={"Unlimited"}
                              className="form-control"
                            />
                          ) : (
                            <input
                              type="number"
                              {...register("allowedNumberOfBookmarks", {
                                required: true
                              })}
                              max={subscriptionModal?.allowedNumberOfBookmarks}
                              min={0}
                              // onChange={(e) => {
                              //   purchasedLicensesField.onChange(e);
                              //   onInputChange(e);
                              // }}
                              className="form-control"
                            />
                          )
                        ) : (
                          <input
                            disabled
                            type="text"
                            className="form-control"
                          />
                        )
                      ) : (
                        planData.plan?.allowedNumberOfBookmarks < 0 ? (
                          <input
                            disabled
                            type="text"
                            defaultValue={"Unlimited"}
                            value={"Unlimited"}
                            className="form-control"
                          />

                        ) : (
                          <input
                            type="number"
                            {...register("allowedNumberOfBookmarks", {
                              required: true
                            })}
                            max={planData.plan?.allowedNumberOfBookmarks}
                            min={0}
                            className="form-control"
                          />
                        )
                      )

                    }

                    <small className="text-danger">
                      {errors.allowedNumberOfBookmarks?.type === "required" &&
                        "Bookmark Licenses is required"}
                      {errors.allowedNumberOfBookmarks?.type === "min" && "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>

                {
                  orgIsAICopilotActive && (
                    <CCol>
                      <CFormGroup>
                        <label>Allowed AI Copilot Users</label>

                        <input
                          type="number"
                          {...register("allowedNoOfAICopilotUser")}
                          // onChange={(e) => {
                          //   purchasedLicensesField.onChange(e);
                          //   onInputChange(e);
                          // }}
                          className="form-control"
                        />
                      </CFormGroup>
                    </CCol>
                  )
                }

              </CRow>

              <CRow>

                <CCol>
                  <CFormGroup>
                    <label>Subscription Type</label>
                    <input
                      {...register("subscriptionType")}
                      type="text"
                      disabled
                      className="form-control"

                    />

                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <input
                      type="checkbox"
                      className="form-check-input ml-1"
                      {...register("autoRenew")}
                      onChange={(e) => changeRenew(e)}
                    //   onChange={(e) =>
                    //     setSubscription((subscription) => ({
                    //       ...subscription,
                    //       autoRenew: !subscription.autoRenew,
                    //     }))
                    //   }
                    // // onChange={() => setSubscription(!subscription.autoRenew)}
                    />
                    <label style={{ fontWeight: "bold", marginLeft: "2rem" }}>
                      Auto Renew
                    </label>
                  </CFormGroup>
                </CCol>
              </CRow>

            </CModalBody>
            {!isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton
                  style={{ background: "var(--primary)", color: "white" }}
                  type="submit"
                  size="sm"
                >
                  Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="danger" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
            {isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton disabled style={{ background: "var(--primary)", color: "white" }} size="sm">
                  <i className="fa fa-spinner fa-spin"></i> Updating
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onCloseClick} color="danger" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
          </form>
        </CModal>
      )
      }
      {
        isloading && (
          <div>
            <Loader />
          </div>
        )
      }
    </div >
  );
};

export default EditSubscriptionModal;
