import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";
import planApi from "services/apis/plan.api";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import SearchLoader from "components/loader/SearchLoader";
import { GetPlanById } from "store/plan/action";


const AddSubscriptionModal = ({ refreshDetails, isOpen, onClose }) => {

  const dispatch: Dispatch<any> = useDispatch();

  const planData = useSelector(
    (state: ApplicationState) => state.plan
  );

  // const [subscription, setSubscription] = useState({
  //   autoRenew: false,
  //   startDate: " ",
  //   endDate: " ",
  //   purchasedLicenses: 0,
  //   planID: " ",
  //   subscriptionTypeID: "",
  //   perLicenseCost: "",
  //   paymentGateway: "",
  //   isActive: false
  // });



  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [dropdownOrganization, setDropDownOrganization] = useState<IOrganizationType[] | null>(null);
  const [plan, setPlan] = useState([]);
  const [isloading, setLoaded] = useState(false);
  const [isSpinning, setSpinning] = useState(false);
  const [subscriptionTypes, setSubscriptionTypes] = useState<ISubscriptionType[] | null>(null);
  const [orgIsAICopilotActive, setOrgIsAICopilotActive] = useState<boolean>(false);
  const [orgDataLoading, setOrgDataLoading] = useState<boolean>(false);
  // const [isPurchasedLicensesDisabled, setIsPurchasedLicensesDisabled] = useState(false);
  // const [purchasedLicenses, setPurchaseLicense] = useState(0);
  // const [cost, setCost] = useState([]);
  // const [paymentGateway, setPaymentGateway] = useState([]);

  useEffect(() => {
    getOrganizationData();
    loadPlan();
    loadSubscriptionTypes();
  }, []);

  

  


  const getOrganizationAICopilotData = (organizationId: number) => {
    setOrgDataLoading(true);
    api.post(Constants.ORGANIZATION_EDIT_URI + "/" + organizationId)
      .then((res) => {
        setOrgIsAICopilotActive(res.data.aiCopilot);
      })
      .finally(() => setOrgDataLoading(false));
  };


  const onInputChange = (e) => {
    setValue("OrgID", Number(e.target.value));
    getOrganizationAICopilotData(Number(e.target.value));
    // setSubscription({ ...subscription, [e.target.name]: e.target.value });
  };

  const onPlanPurchasedChange = (e) => {
    setValue("IsPurchased", e.target.value);
    // setSubscription({ ...subscription, [e.target.name]: e.target.value });
  };


  // function handleSelectChange(e) {
  //   const alldata = [];
  //   e.map((item) => {
  //     alldata.push(item.value);
  //   });
  //   setPaymentGateway(alldata);
  // }

  const loadPlan = () => {
    api.get(Constants.PLAN_GET_URI).then((res) => {
      setPlan(res.data);
    });
  };

  const getOrganizationData = () => {
    //setLoaded(true);
    api.get(Constants.ORGANIZATION_GET_URI)
      .then((res) => {
        setDropDownOrganization(res.data);
        setLoaded(false);
      });
  };


  const loadSubscriptionTypes = () => {
    api.get(Constants.SUBSCRIPTION_TYPES_GET_ALL_URI).then((res) => {
      setSubscriptionTypes(res.data);
    });
  };


  // const handlePlanChange = (e) => {
  //   let event = e.target;
  //   let perLicenceCost = planDropDown.filter((item) => item.value == event.value)[0].perLicenceCost;
  //   setSubscription({
  //     ...subscription,
  //     planID: event.value,
  //     perLicenseCost: perLicenceCost.toString(),
  //   });
  // }

  const changeRenew = (e: any) => {
    setValue("autoRenew", e.target.checked);
  }


  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue("planID", Number(e.target.value));
    planApi.getPlanById(dispatch, Number(e.target.value));
  };

  const planDropDown = plan.map((item: Iplan) => {
    return {
      label: item.name,
      value: item.id,
      perLicenceCost: item.perLicenceCost,
    };
  });

  const organizationDropDown = dropdownOrganization?.map((item: IOrganizationType) => {
    return {
      label: item.name,
      value: item.organizationID,
    };
  });

  const planPurchased = [
    {
      id: 1,
      label: "Purchased",
      value: "true",
    },
    {
      id: 2,
      label: "Not-Purchased",
      value: "false"
    }
  ]

  const trialStartDateField = register("trialStartDate", {
    required: false,
  });
  const trialEndDateField = register("trialEndDate", {
    required: false,
  });
  const StartDateField = register("startDate", {
    required: false,
  });
  const purchasedLicensesField = register("purchasedLicenses", {
    required: false,

  });
  const bookmarkCountField = register("allowedNumberOfBookmarks", {
    required: false,
  });

  // const OrganizationField = register("OrgID", {
  //   required: true,
  // });

  // const SubscriptionTypeField = register("subscriptionType", {
  //   required: true,
  // });

  // const planField = register("planID", {
  //   required: true,
  // });

  // const options = [{ value: "Paypal", label: "PayPal" }];

  const onSubmit = (data) => {
    setSpinning(true);

    
    
    const subscriptionData = {
      ...data,
      IsPurchased: data.IsPurchased === "true",
      isActive: true,
      allowedNoOfAICopilotUser:data.allowwedNoOfCopilotUser===undefined? 0: Number(data.allowedNoOfAICopilotUser)
    };
    

    api.post(Constants.SUBSCRIPTION_CREATE_URI, subscriptionData)
      .then(async (response) => {
        console.log("response data", response);
        toast.success("😊 Subscription added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        refreshDetails();
        dispatch(GetPlanById(undefined));
      }).catch((error) => {

        toast.error(`😔 ${error.response}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }).finally(() => {
        setSpinning(false);
       
      });
  };


  useEffect(() => {
    if (planData.plan) {
      setValue("purchasedLicenses", planData.plan.minimumLicenceCount)
      setValue("perLicenseCost", planData.plan.perLicenceCost)
      setValue("allowedNumberOfBookmarks", planData.plan.allowedNumberOfBookmarks)
      setValue("subscriptionTypeId", planData.plan.subscriptionTypeId)

      const trialUTCStartDate = new Date();
      const formattedTrialStartDate = trialUTCStartDate.toISOString().split("T")[0];
      setValue("trialStartDate", formattedTrialStartDate);

      const trialUTCEndDate = new Date(trialUTCStartDate);
      trialUTCEndDate.setUTCDate(trialUTCStartDate.getUTCDate() + 30);
      const formattedTrialEndDate = trialUTCEndDate.toISOString().split("T")[0];
      setValue("trialEndDate", formattedTrialEndDate);

      const startDate = new Date(trialUTCEndDate);
      startDate.setUTCDate(trialUTCEndDate.getUTCDate() + 1);
      const formattedStartDateNext = startDate.toISOString().split("T")[0];
      setValue("startDate", formattedStartDateNext);

      let endDate: Date;
      if (planData.plan.subscriptionType.toLowerCase() === "monthly" || planData.plan.subscriptionType.toLowerCase() === "free") {
        endDate = new Date(startDate);
        endDate.setUTCDate(startDate.getUTCDate() + 30);
        const formattedEndDate = endDate.toISOString().split("T")[0];
        setValue("endDate", formattedEndDate);
      } else if (planData.plan.subscriptionType.toLowerCase() === "annual") {
        endDate = new Date(startDate);
        endDate.setUTCFullYear(startDate.getUTCFullYear() + 1);
        const formattedEndDate = endDate.toISOString().split("T")[0];
        setValue("endDate", formattedEndDate);
      }
    }
  }, [planData.plan]);

  const onCloseClick = () => {
    dispatch(GetPlanById(undefined));
    onClose();
  }

  return (
    <div>
      {!isloading && plan && subscriptionTypes && dropdownOrganization && (
        <CModal show={isOpen} onClose={onCloseClick} >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Add New subscription</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="subscription-modal-body" >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >

              <CRow>
                <CCol>
                  {" "}
                  <CFormGroup>
                    <label className="control-label">Trial Start Date</label>
                    <input
                      type="date"
                      disabled
                      {...trialStartDateField}
                      // onChange={(e) => {
                      //   trialStartDateField.onChange(e);
                      //   onInputChange(e);
                      // }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.trialStartDate?.type === "required" &&
                        "Trial Start Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label className="control-label">Trial End Date</label>
                    <input
                      disabled
                      type="date"
                      {...trialEndDateField}
                      // onChange={(e) => {
                      //   EndDateField.onChange(e);
                      //   onInputChange(e);
                      // }}
                      // min={subscription.startDate}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.trialEndDate?.type === "required" &&
                        "Trial End Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  {" "}
                  <CFormGroup>
                    <label className="control-label">Start Date</label>
                    <input
                      disabled
                      type="date"
                      {...StartDateField}
                      // onChange={(e) => {
                      //   StartDateField.onChange(e);
                      //   onInputChange(e);
                      // }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.startDate?.type === "required" &&
                        "Start Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label className="control-label">End Date</label>
                    <input
                      disabled
                      type="date"
                      {...register("endDate")}
                      // onChange={(e) => {
                      //   EndDateField.onChange(e);
                      //   onInputChange(e);
                      // }}
                      // min={subscription.startDate}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.endDate?.type === "required" &&
                        "End Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>User Licenses*</label>

                    {
                      planData.plan !== undefined ? (
                        planData.plan?.minimumLicenceCount < 0 ? (
                          <input
                            disabled
                            type="text"
                            defaultValue={"Unlimited"}
                            value={"Unlimited"}
                            className="form-control"
                          />
                        ) : (
                          <input
                            type="number"
                            {...purchasedLicensesField} // Spread the existing field values
                            max={planData.plan?.minimumLicenceCount}
                            min={1}
                            className="form-control"
                          // disabled={isPurchasedLicensesDisabled} // Uncomment this line if you want to conditionally disable
                          />
                        )
                      ) : (
                        <input
                          disabled
                          type="text"
                          className="form-control"
                        />
                      )
                    }


                    <small className="text-danger">
                      {errors.minimumLicenceCount?.type === "required" &&
                        "License is required"}
                      {/* {errors.purchasedLicenses?.type === "min" && "Please enter a positive number"} */}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Per License Cost*</label>
                    <input
                      disabled
                      {...register("perLicenseCost", {
                        min: 0,
                      })}
                      // name="perLicenseCost"
                      // value={subscription.perLicenseCost}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                    <small className="text-danger">
                      {errors.perLicenseCost?.type === "required" &&
                        "License Cost is required"}
                      {errors.perLicenseCost?.type === "min" && "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>


              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Bookmark Licenses*</label>
                    {
                      planData.plan !== undefined ? (
                        planData.plan?.allowedNumberOfBookmarks < 0 ? (
                          <input
                            disabled
                            type="text"
                            defaultValue={"Unlimited"}
                            value={"Unlimited"}
                            className="form-control"
                          />
                        ) : (
                          <input
                            type="number"
                            {...bookmarkCountField}
                            max={planData.plan?.allowedNumberOfBookmarks}
                            min={1}
                            // onChange={(e) => {
                            //   purchasedLicensesField.onChange(e);
                            //   onInputChange(e);
                            // }}
                            className="form-control"
                          />
                        )
                      ) : (
                        <input
                          disabled
                          type="text"
                          className="form-control"
                        />
                      )
                    }

                    <small className="text-danger">
                      {errors.allowedNumberOfBookmarks?.type === "required" &&
                        "Bookmark Licenses is required"}
                      {errors.allowedNumberOfBookmarks?.type === "min" && "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>

                <CCol>
                  <CFormGroup>
                    <label>Plan Type</label>
                    <input
                      disabled
                      defaultValue={planData?.plan?.planType}
                      // name="perLicenseCost"
                      // value={subscription.perLicenseCost}
                      className="form-control"
                    // onChange={(e) => onInputChange(e)}
                    />
                    {/* <small className="text-danger">
                      {errors.planType?.type === "required" &&
                        "Plan Type is required"}
                    </small> */}
                  </CFormGroup>
                </CCol>

                <CCol>
                  <CFormGroup>
                    <label>Subscription Type</label>
                    <input type="text" disabled className="form-control"
                      defaultValue={planData?.plan?.subscriptionType}
                    />
                    {/* <select
                      disabled
                      {...SubscriptionTypeField}
                      // onChange={(e) => {
                      //   SubscriptionTypeField.onChange(e);
                      //   onInputChange(e);
                      //   const selectedSubscriptionTypeID = parseInt(e.target.value);
                      //   if (selectedSubscriptionTypeID === 4) {
                      //     setSubscription((prevSubscription) => ({
                      //       ...prevSubscription,
                      //       purchasedLicenses: 0,
                      //     }));
                      //     setPurchaseLicense(0)
                      //     setIsPurchasedLicensesDisabled(true);
                      //   } else {
                      //     setIsPurchasedLicensesDisabled(false);
                      //     setPurchaseLicense(0);
                      //   }
                      // }}
                      className="form-control"
                    >
                      <option ></option>
                      {subscriptionTypes.map((temp: ISubscriptionType, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={temp.id}>
                              {temp.type}{" "}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </select> */}
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  {" "}
                  <CFormGroup >
                    <label>Plan*</label>
                    <Controller
                      name="planID"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          // options={planDropDown}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handlePlanChange(e);
                          }}
                          style={{
                            position: "relative"
                          }}
                          className="form-control"
                        >
                          <option></option>
                          {
                            planDropDown.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                </React.Fragment>
                              );
                            })
                          }


                        </select>
                      )}
                    />
                    {planData?.isGetPlanByIdLoading && (
                      <div className="plan-loading">
                        <SearchLoader />
                      </div>
                    )}

                    <small className="text-danger">
                      {errors.planID?.type === "required" && "Plan is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Payment Gateway</label>
                    {/* <CreatableSelect
                      name="paymentGateway"
                      custom
                      isClearable
                      options={options}
                      onChange={(e) => handleSelectChange(e)}
                    /> */}
                    <input
                      className="form-control"
                      {...register("paymentGateway")}
                    // onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup >
                    <label>Organization*</label>

                    <Controller
                      name="OrgID"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onInputChange(e);
                          }}

                          className="form-control"
                        >
                          <option></option>
                          {
                            organizationDropDown?.map((item, index) => (
                              <React.Fragment key={index}>
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              </React.Fragment >
                            ))
                          }
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.OrgID?.type === "required" &&
                        "Organization is required"}
                    </small>
                  </CFormGroup>

                  


                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup >
                    <label>Plan Purchase*</label>

                    <Controller
                      name="IsPurchased"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onPlanPurchasedChange(e);
                          }}

                          className="form-control"
                        >
                          <option></option>
                          {
                            planPurchased?.map((item, index) => (
                              <React.Fragment key={index}>
                                <option value={item.value} >
                                  {item.label}
                                </option>
                              </React.Fragment >
                            ))
                          }
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.IsPurchased?.type === "required" &&
                        "Plan Purchase is required"}
                    </small>
                  </CFormGroup>


                </CCol>

                {
                  orgIsAICopilotActive && (
                    <CCol>
                      <CFormGroup>
                        <label>Allowed AI Copilot Users</label>

                        <input
                          type="number"
                          {...register("allowedNoOfAICopilotUser")}
                          // onChange={(e) => {
                          //   purchasedLicensesField.onChange(e);
                          //   onInputChange(e);
                          // }}
                          className="form-control"
                          min={1}
                        />
                      </CFormGroup>
                    </CCol>
                  )
                }

              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <input
                      type="checkbox"
                      className="form-check-input ml-1"
                      {...register("autoRenew")}
                      onChange={(e) => changeRenew(e)}
                    //   onChange={(e) =>
                    //     setSubscription((subscription) => ({
                    //       ...subscription,
                    //       autoRenew: !subscription.autoRenew,
                    //     }))
                    //   }
                    // // onChange={() => setSubscription(!subscription.autoRenew)}
                    />
                    <label style={{ fontWeight: "bold", marginLeft: "2rem" }}>
                      Auto Renew
                    </label>
                  </CFormGroup>
                </CCol>
                <CCol></CCol>
              </CRow>

              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton style={{ background: "var(--primary)", color: "white" }}
                    type="submit" size="sm">
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onCloseClick} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton disabled style={{ background: "var(--primary)", color: "white" }}
                    size="sm">
                    <i className="fa fa-spinner fa-spin"></i> Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onCloseClick} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )
      }
      {isloading && <Loader />}
    </div >
  );
};

export default AddSubscriptionModal;