import { CButton, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch } from "redux";
import planApi from "services/apis/plan.api";
import { ApplicationState } from "store";
import { setAddPlanModal } from "store/plan/action";
import PlanFormElements from "./planFormElement";

interface IAddPlanModalProps {
  isOpen: boolean | undefined;
}

export interface formStateProps {
  name: string;
  minimumLicenceCount: string;
  perLicenceCost: string;
  allowedNumberOfBookmarks: string;
  skuid: string;
  planType: string;
  subscriptionTypeID: string;
}

export const planType = [
  { label: "Legal", value: "legal" },
  { label: "Non-Legal", value: "non-legal" },
];


const AddPlanModal = ({ isOpen }: IAddPlanModalProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [checkPlanExistBySkUid, setCheckPlanExistBySkUid] = useState(false);
  const [isCheckingBySkUidLoading, setIsCheckingBySkUidLoading] = useState(false);
  const [checkPlanExistByName, setCheckPlanExistByName] = useState(false);
  const [isPlanCheckingByNameLoading, setIsPlanCheckingByNameLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const [formState, setFormState] = useState<formStateProps>({
    name: "",
    minimumLicenceCount: "",
    perLicenceCost: "",
    allowedNumberOfBookmarks: "",
    skuid: "",
    planType: "non-legal",
    subscriptionTypeID: "1"
  });



  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const checkPlanNameExistByName = async (keyword: string) => {
    if (keyword.trim() !== '') {
      setIsPlanCheckingByNameLoading(true)
      try {
        const res = await planApi.checkPlanExistByName(keyword);
        setCheckPlanExistByName(res);


      } catch {
        setCheckPlanExistByName(false);
      } finally {
        setIsPlanCheckingByNameLoading(false)
      }
    }
  }

  const checkPlanExistBySKuid = async (keyword: string) => {
    if (keyword.trim() !== '') {
      setIsCheckingBySkUidLoading(true)
      try {
        const res = await planApi.checkPlanBySkuid(keyword);
        setCheckPlanExistBySkUid(res);
      } catch {
        setCheckPlanExistBySkUid(false);
      } finally {
        setIsCheckingBySkUidLoading(false)
      }

    } else {
      setCheckPlanExistBySkUid(false);
    }
  }

  const subscriptionTypes = useSelector(
    (state: ApplicationState) => state.subscription?.subscriptionTypes
  );

  const mappedSubscriptionTypes = subscriptionTypes.map((el) => ({
    label: el.type,
    value: el.id.toString(),
  }));


  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<ISelectData|undefined>( mappedSubscriptionTypes[0]);

  const [selectedPlanType, setSelectedPlanType] = useState<ISelectData|undefined>(
    formState.planType === "legal" ? planType[0] : planType[1]
  );

  function closeHandler() {
    setFormState({
      name: "",
      minimumLicenceCount: "",
      perLicenceCost: "",
      allowedNumberOfBookmarks: "",
      skuid: "",
      planType: "non-legal",
      subscriptionTypeID: "1"
    })
    setCheckPlanExistByName(false)
    setCheckPlanExistBySkUid(false)
    setSelectedSubscriptionType(mappedSubscriptionTypes[0])
    setSelectedPlanType(planType[1])
    dispatch(setAddPlanModal({ showModal: false }));
  }

  function submitHandler(data: formStateProps) {
    const newData = {
      ...data,
      perLicenceCost: Number(data.perLicenceCost),
      minimumLicenceCount: Number(data.minimumLicenceCount),
      subscriptionTypeID: Number(data.subscriptionTypeID),
      allowedNumberOfBookmarks: Number(data.allowedNumberOfBookmarks)
    }

    setIsSpinning(true);
    planApi
      .createNewPlan(dispatch, newData)
      .then(() => {
        toast.success("😊 Plan added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while adding plan!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

      }).finally(() => {
        setIsSpinning(false);
      });
  }

  useEffect(() => {
    const areFieldsEmpty = Object.values(formState).some((value) => value.trim() === "");
    if (checkPlanExistBySkUid || checkPlanExistByName || areFieldsEmpty) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [checkPlanExistByName, checkPlanExistBySkUid, formState]);

 

  return (
    <div>
      {subscriptionTypes &&  (
        <CModal size="lg" className={[
          "avish-modal",
        ].join(" ")} show={isOpen} onClose={() => closeHandler()} closeOnBackdrop={true}>
          <CModalHeader closeButton>
            <CModalTitle>
              Add Plan
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <PlanFormElements
              checkPlanExistBySkUid={checkPlanExistBySkUid}
              isCheckingBySkUidLoading={isCheckingBySkUidLoading}
              checkPlanExistByName={checkPlanExistByName}
              isPlanCheckingByNameLoading={isPlanCheckingByNameLoading}
              checkPlanNameExistByName={checkPlanNameExistByName}
              checkPlanExistBySKuid={checkPlanExistBySKuid}
              onChange={handleInputChange}
              formState={formState}
              setFormState={setFormState}
              setSelectedSubscriptionType={setSelectedSubscriptionType}
              selectedSubscriptionType={selectedSubscriptionType}
              setSelectedPlanType={setSelectedPlanType}
              selectedPlanType={selectedPlanType}
              mappedSubscriptionTypes={mappedSubscriptionTypes}
            ></PlanFormElements>

            {!isSpinning && (
              <div
                style={{
                  placeItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "10px",
                  float: "right",
                }}
              >
                <CButton
                  style={{
                    background: "var(--primary)",
                    color: "white",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer"

                  }}
                  type="submit"
                  size="sm"
                  onClick={() => submitHandler(formState)}
                  disabled={isButtonDisabled}
                >
                  Add
                </CButton>

                <div className="ml-1">
                  <CButton color="danger"

                    onClick={() => closeHandler()} size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}

            {isSpinning && (
              <div
                style={{
                  placeItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "10px",
                  float: "right",
                }}
              >
                <CButton
                  disabled
                  style={{ background: "var(--primary)", color: "white" }}
                  size="sm"
                >
                  <i className="fa fa-spinner fa-spin"></i> Adding
                </CButton>
                <div className="ml-1">
                  <CButton color="danger" onClick={() => closeHandler()} size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}


          </CModalBody >
        </CModal>
      )
      }
    </div>


  )
};

export default AddPlanModal;
