import AvishInput from "components/formElement/inputWrapper";
import Select from "react-select";
import { formStateProps, planType } from "./addPlanModal";

interface IPlanFormElementProps {
  plan?: Iplan | null;
  checkPlanExistBySkUid: boolean;
  isCheckingBySkUidLoading: boolean;
  checkPlanExistByName: boolean;
  isPlanCheckingByNameLoading: boolean;
  checkPlanNameExistByName: (keyword: string) => Promise<void>;
  checkPlanExistBySKuid: (keyword: string) => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  formState: formStateProps;
 setFormState: React.Dispatch<React.SetStateAction<formStateProps>>
 setSelectedSubscriptionType: React.Dispatch<React.SetStateAction<ISelectData | undefined>>
 selectedSubscriptionType: ISelectData | undefined
 setSelectedPlanType: React.Dispatch<React.SetStateAction<ISelectData | undefined>>
 selectedPlanType: ISelectData | undefined;
 mappedSubscriptionTypes: {
  label: string;
  value: string;
}[]
}

const PlanFormElements = ({ 
  plan = null,
  checkPlanExistByName,
  isPlanCheckingByNameLoading,
  checkPlanNameExistByName,
  isCheckingBySkUidLoading,
  checkPlanExistBySKuid,
  checkPlanExistBySkUid,
  onChange,
  formState,
  setFormState,
  setSelectedSubscriptionType,
  selectedSubscriptionType,
  setSelectedPlanType,
  selectedPlanType,
  mappedSubscriptionTypes
}: IPlanFormElementProps) => {




  return (
    <>
      <AvishInput
        label="Plan Name*"
        required={true}
        name="name"
        inputType="Skuid"
        min={1}
        value={formState.name}
        checkSkuidExist={checkPlanNameExistByName}
        isCheckingLoading={isPlanCheckingByNameLoading}
        checkExist={checkPlanExistByName}
        onChange={onChange}
        errorMessage="Plan Name Already Exist."
        InvalidError={checkPlanExistByName}
      ></AvishInput>

      <div className="form-groups">
        <AvishInput
          label="Maximum Licenses*"
          name="minimumLicenceCount"
          type="number"
          required={true}
          min={1}
          maxLength={4}
          onChange={onChange}
          value={formState.minimumLicenceCount}
        ></AvishInput>
        <AvishInput
          label="Cost per License (USD)*"
          name="perLicenceCost"
          type="number"
          required={true}
          min={1}
          maxLength={4}
          onChange={onChange}
          value={formState. perLicenceCost}
        ></AvishInput>
      </div>
      <div className="form-groups">
        <AvishInput
          label="Bookmark Limit*"
          name="allowedNumberOfBookmarks"
          type="number"
          required={true}
          min={1}
          maxLength={4}
          onChange={onChange}
          value={formState.allowedNumberOfBookmarks}
        ></AvishInput>
        <AvishInput
          label="SKU ID*"
          name="skuid"
          inputType="Skuid"
          required={true}
          min={1}
          value={formState.skuid}
          checkSkuidExist={checkPlanExistBySKuid}
          isCheckingLoading={isCheckingBySkUidLoading}
          onChange={onChange}
          checkExist={checkPlanExistBySkUid}
          className="skuid-input"
          errorMessage="Skuid Already Exist."
          InvalidError={checkPlanExistBySkUid}
        ></AvishInput>
      </div>
      <AvishInput
        label="Plan Type*"
        name="planType"
        className="overflow-auto "
        input={
          <Select
            className="basic-single customZindex"
            classNamePrefix="select"
            value={selectedPlanType}
            isClearable
            isSearchable
            menuPlacement="auto"
            name="planType"
            options={planType}
            onChange={(selected:any) =>{
              setFormState((prev)=>({
                ...prev,
                planType:selected.value
              }))
              setSelectedPlanType(selected as ISelectData)
            }}
          />
        }
      ></AvishInput>
      <AvishInput
        label="Subscription Type*"
        name="subscriptionTypeID"
        className="overflow-auto "
        input={
          <Select
            className="basic-single customZindex"
            classNamePrefix="select"
            value={selectedSubscriptionType}
            isClearable
            isSearchable
            menuPlacement="auto"
            name="subscriptionTypeID"
            options={mappedSubscriptionTypes}
            onChange={(selected:any) =>{
              setFormState((prev)=>({
                ...prev,
                subscriptionTypeID:selected.value.toString()
              }))
              setSelectedSubscriptionType(selected as ISelectData)
            } }
          />
        }
      ></AvishInput>


    </>
  );
};

export default PlanFormElements;
