const subscriptionHistoryVariables = {
  fields: [
    {
      key: "organizationName",
      label: "Organization Name",
      _style: { width: "23%" },
    },
    {
      key: "planName",
      label: "Plan",
      _style: { width: "10%" },
    },
    {
      key: "subscriptionType",
      label: "Subscription Type",
      _style: { width: "15%" },
    },
    {
      key: "isFreeTrial",
      label: "IsFreeTrial",
      _style: { width: "15%" },
    },
    { key: "subscriptionDate", _style: { width: "14%" } },
    { key: "isActive", _style: { width: "8%" } },
    // { key: "purchasedLicenses", _style: { width: "14%" } },
    { key: "manageLicense", _style: { width: "14%" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "2%", textAlign: "center", paddingRight: "0.22rem" },
      sorter: false,
      filter: false,
    },
  ],

  CSVHeaders: [
    { key: "organizationName", label: "Organization" },
    { key: "subscriptionType", label: "Subscription Type" },
    {
      key: "planName",
      label: "Plan",
      _style: { width: "10%" },
    },
    { key: "msThirdPartySubscriptionID", label: "Third PartyID" },
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "isActive", label: "Is Active" },
    // { key: "purchasedLicenses", label: "Purchased Licenses" },
    // { key: "perLicenseCost", label: "Per License Cost" },
  ],
};

export default subscriptionHistoryVariables;
