import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../../constants/Constants";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteOrganizationModal = ({ organizationID, refreshDetails, isOpen, onClose }) => {
  const [isSpinning, setSpinning] = useState(false);

  const deleteOrganization = async () => {
    setSpinning(true);
    await api
      .delete(Constants.ORGANIZATION_DELETE_URI + "/" + organizationID)
      .then(async (res) => {
        toast.success("😊 Organization deleted successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setSpinning(false);
        //console.log(`User Deleted successfully!`);
        refreshDetails();
      })
      .catch((error) => {
        console.log("error",error);
        if(error.status===400){
            toast.error(error.response, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
        }else{
            toast.error(`😔 Error while deleting user!`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
        }
    
      })
      .finally(() => {
        onClose();
      })

  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div>Do you want to delete this Organization?</div>
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="danger" onClick={() => deleteOrganization()} size="sm">
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="primary" disabled size="sm">
                <i className="fa fa-spinner fa-spin"></i> Deleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default DeleteOrganizationModal;
