import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import { useState } from "react";

const ViewSubscriptionDetails = ({
  subscriptionDetails,
  refreshDetails,
  isOpen,
  onClose,
}) => {

  console.log("subscriptionDetails", subscriptionDetails);
  

  const [isLoading, setLoaded] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  return (
    <div>
      {!isLoading && isOpen && subscriptionDetails && (
        <CModal
          show={isOpen}
          onClose={onClose}
          closeOnBackdrop={false}
          style={{ borderTop: "5px solid #ccc" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Subscription's Details</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  href="#"
                  active={activeKey === 1}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(1);
                  }}
                >
                  Organization Details
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#"
                  active={activeKey === 2}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(2);
                  }}
                >
                  License
                </CNavLink>
              </CNavItem>
            {
              !subscriptionDetails.isFreePlan && (
                <CNavItem>
                <CNavLink
                  href="#"
                  active={activeKey === 3}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveKey(3);
                  }}
                >
                  Date
                </CNavLink>
              </CNavItem>
              )
            }
            </CNav>

            <CTabContent>
              {activeKey === 1 ? (
                <div className="tap-pane-show">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Organization Name</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.organizationName ? subscriptionDetails.organizationName : "N/A"}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Organization Email</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.organizationEmail ? subscriptionDetails.organizationEmail : "N/A"}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Account Type</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.organizationAccountType ? subscriptionDetails.organizationAccountType : "N/A"}</span>
                    </div>
                  </div>

                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Active:</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.isActive ? "Yes" : "No"}</span>
                    </div>
                  </div>

                </div>
              ) : (
                ""
              )}
              {activeKey === 2 ? (
                <div className="container">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Plan Name:</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.planName}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Subscription Type:</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.subscriptionType ? subscriptionDetails.subscriptionType : "N/A"}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">ThirdParty SubscriptionID:</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.msThirdPartySubscriptionID ? subscriptionDetails.msThirdPartySubscriptionID : "N/A"}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Subscription Purchased:</span>
                    </div>
                    <div className="col-md-6">
                      <span>{subscriptionDetails.isPurchased ? "Purchased" : "Not Purchased"}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Purchased Licenses:</span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        {
                          subscriptionDetails.purchasedLicenses < 0 ? "Unlimited"
                            : subscriptionDetails.purchasedLicenses === 0 ? "N/A"
                              : subscriptionDetails.purchasedLicenses
                        }
                      </span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Bookmark Licenses:</span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        {
                          subscriptionDetails.allowedNumberOfBookmarks < 0 ? "Unlimited"
                            : subscriptionDetails.allowedNumberOfBookmarks === 0 ? "N/A"
                              : subscriptionDetails.allowedNumberOfBookmarks
                        }
                      </span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">Is Trial </span>
                    </div>
                    <div className="col-md-6">
                      <span>
                       {
                        subscriptionDetails.isFreeTrial && !subscriptionDetails.isFreePlan ?"Yes":"No"
                       }
                      </span>
                    </div>
                  </div>


                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-6">
                      <span className="font-weight-bold">AICopilot Licenses:</span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        {
                          subscriptionDetails.allowedNoOfAICopilotUser < 0 ? "Unlimited"
                            : subscriptionDetails.allowedNoOfAICopilotUser === 0 ? "N/A"
                              : subscriptionDetails.allowedNoOfAICopilotUser
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activeKey === 3 ? (
                <div className="container">

                  {
                    subscriptionDetails.isFreeTrial && !subscriptionDetails.isFreePlan && (
                      <>
                        <div className="row p-2">
                          <div className="col-md-6">
                            <span className="font-weight-bold">Trial Start Date:</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              {moment(subscriptionDetails.trialStartDate).format(
                                "MMM DD YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="row p-2">
                          <div className="col-md-6">
                            <span className="font-weight-bold">Trial End Date:</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              {moment(subscriptionDetails.trialEndDate).format("MMM DD YYYY")}
                            </span>
                          </div>
                        </div>
                        <hr className="my-0" />
                      </>
                    )
                  }


                  {
                    !subscriptionDetails.isFreeTrial && !subscriptionDetails.isFreePlan && (
                      <>
                        <div className="row p-2">
                          <div className="col-md-6">
                            <span className="font-weight-bold">Start Date:</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              {moment(subscriptionDetails.startDate).format(
                                "MMM DD YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="row p-2">
                          <div className="col-md-6">
                            <span className="font-weight-bold">End Date:</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              {moment(subscriptionDetails.endDate).format("MMM DD YYYY")}
                            </span>
                          </div>
                        </div>

                      </>
                    )
                  }

                </div>
              ) : (
                ""
              )}

            </CTabContent>




          </CModalBody>
          <CModalFooter>
            <CButton color="danger" size="sm" onClick={onClose}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      <div />
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ViewSubscriptionDetails;
