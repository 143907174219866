import React from 'react';
import { DTablePagination } from 'components/datatable/pagination';
import DataTableNoContent from 'components/datatable/datatableNoContent';
import Loader from 'components/loader/Loader';

interface ActivityProps {
    activityData: IUserActivity[];
    isPending: boolean;
    isError: boolean;
    totalDataCount: number;
    pagination: number;
    setPagination: React.Dispatch<React.SetStateAction<any>>;
  
}

const ActivityTable: React.FC<ActivityProps> = ({
    activityData,
    isPending,
    isError,
    totalDataCount,
    pagination,
    setPagination
}) => {
    const getSourceBadgeClass = (source?: string) => {
        const sourceLower = source?.toLowerCase();
        switch (sourceLower) {
            case 'web': return 'source-badge source-badge--web';
            case 'teams': return 'source-badge source-badge--teams';
            case 'outlook': return 'source-badge source-badge--outlook';
            case 'word': return 'source-badge source-badge--word';
            default: return 'source-badge source-badge--default';
        }
    };

    const getAppTypeBadgeClass = (appType?: string) => {
        const appTypeLower = appType?.toLowerCase();
        switch (appTypeLower) {
            case 'webapp': return 'app-type-badge app-type-badge--webapp';
            case 'adminportal': return 'app-type-badge app-type-badge--adminportal';
            case 'superadmin': return 'app-type-badge app-type-badge--superadmin';
            default: return 'app-type-badge app-type-badge--default';
        }
    };

    const getActivityTypeBadgeClass = (activityType?: string) => {
        const activityTypeLower = activityType?.toLowerCase();
        switch (activityTypeLower) {
            case 'login': return 'activity-type-badge activity-type-badge--login';
            case 'logout': return 'activity-type-badge activity-type-badge--logout';
            default: return 'activity-type-badge activity-type-badge--default';
        }
    };

    const formatSource = (source?: string) => {
        if (!source) return 'N/A';
        return source === 'word' ? 'MS Word' : source.toUpperCase();
    };

    const formatAppType = (appType?: string) => {
        if (!appType) return 'N/A';
        return appType.toLowerCase() === 'webapp' ? 'WEB APP' : appType.toUpperCase();
    };

    const formatMessage = (organizationName:string,message:string) => {
      
        const actionRegex = /(signed in|signed out|attempted to|signed up|logged in|logged out)/i;
        const match = actionRegex.exec(message);

        if (match) {
            const actionIndex = match.index ; 
            const userInfo = message.substring(0, actionIndex).trim();
            const restOfMessage = message.substring(actionIndex);
            if(organizationName===null){
                return `${userInfo} ${restOfMessage}`;
            }
            
            return `${userInfo} from ${organizationName.toUpperCase()} ${restOfMessage}`;
        }

     
        return message;
    };

 


    return (
        <div className="activity-table">
            <table className="table">
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Source</th>
                        <th>App Type</th>
                        <th>Activity Type</th>
                    </tr>
                </thead>
                <tbody>
                    {!isPending && !isError && activityData.length > 0 &&
                        activityData.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div >
                                            <div className="activity-name"
                                                style={{
                                                    color: item.isActive ? '' : 'rgb(193 0 0)',
                                                    fontStyle: item.isActive ? 'normal' : 'italic',
                                                    opacity: item.isActive ? 1 : 0.7,
                                                   
                                                }}
                                            >
                                                {formatMessage(item.organizationName,item.activity) || 'N/A'}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span className={getSourceBadgeClass(item.source)}
                                        style={{
                                            opacity: item.isActive ? 1 : 0.7,
                                        }}
                                    >
                                        {formatSource(item.source)}
                                    </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span className={getAppTypeBadgeClass(item.appType)}
                                        style={{
                                            opacity: item.isActive ? 1 : 0.7,
                                        }}
                                    >
                                        {formatAppType(item.appType)}
                                    </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span className={getActivityTypeBadgeClass(item.activityType)}
                                        style={{
                                            opacity: item.isActive ? 1 : 0.7,
                                        }}
                                    >
                                        {item.activityType ? item.activityType.toUpperCase() : 'N/A'}
                                    </span>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {isPending && (
                <div className="table-loader">
                    <Loader />
                </div>
            )}

            {!isPending && !isError && activityData.length <= 0 && (
                <div className="no-data--empty">
                    <DataTableNoContent />
                </div>
            )}

            {!isPending && isError && (
                <div className="no-data--error">
                    <DataTableNoContent />
                </div>
            )}

            <div className="pagination">
                <DTablePagination
                    tPage={totalDataCount}
                    cPage={pagination}
                    onPageChange={(e) => setPagination(e)}
                    name="activity"
                />
            </div>
        </div>
    );
};

export default ActivityTable;