import React, { useContext, useEffect, useState } from "react";

import { TotalCountContext } from "context/TotalCount";

import { CDataTable, CNav, CNavItem, CNavLink, CTabContent } from "@coreui/react";
import axios from "axios";
import Constants from "constants/Constants";
import { useHistory } from "react-router-dom";

import {
  CategoryScale,
  Chart as ChartJS, //x-asix
  LinearScale,
  LineElement, //y-axis
  PointElement,
  Tooltip
} from "chart.js";
import MonthList from "helper/GetMonth";
import { Line } from "react-chartjs-2";
import { FaArrowRight } from "react-icons/fa";
import Ellipse6 from "./icons/Ellipse 6.svg";
import active from "./icons/active.svg";
import deleted from "./icons/deleted.svg";
import inactive from "./icons/inactive.svg";
import organization from "./icons/organization.svg";
import subscription from "./icons/subscription.svg";
import user from "./icons/user.svg";
import { Bounce, toast } from "react-toastify";
import moment from "moment";

const boxShadowPlugin = {
  id: 'boxShadow',
  beforeDraw(chart, args, options) {
    const { ctx } = chart;
    ctx.shadowColor = '#979699';
    ctx.shadowBlur = 20;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
  },
  afterDraw(chart, args, options) {
    const { ctx } = chart;
    ctx.shadowColor = 'rgba(0, 0, 0, 0)';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
  }
};


ChartJS.register(
  LineElement,
  CategoryScale, //x-asix
  LinearScale, //y-axis
  PointElement,
  Tooltip,
  boxShadowPlugin
)


const Dashboard = () => {
  const [xLabels, setXLabels] = useState<string[]>([]);

  const data = useContext(TotalCountContext);
  const [leads, setLeads] = React.useState([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [leadData, setleadData] = useState<number[]>();
  const [subscriptionData, setsubscriptionData] = useState<number[]>();
  const [actualSubscriptionData, setActualSubscriptionData] = useState<number[]>();
  const [actualLeadsData, setActualLeadsData] = useState<number[]>();
  const [activeKey, setActiveKey] = useState(1);
  const [subscriptionExpiredType, setsubscriptionExpiredType] = useState("today");
  const [subscriptionExpiryOrg,setSubscriptionExpiryOrg]=useState<ISubscription[]>();

  const [labelData, setLabelData] = useState(xLabels);

  let history = useHistory();
  const hadleClick = () => {
    history.push("/leads");
  };


  useEffect(() => {
    axios.get(Constants.LEADS_GET_URI).then((res) => {
      setLeads(res.data.slice(0, 5));
      setLoading(false);
    });
    fetchSubscriptionByExpiryType("today");
  }, []);



  useEffect(() => {
    const monthNames = MonthList.getMonthList();
    setXLabels(monthNames);
    if (data.annualSubscriptionData) {
      const alignedSubscriptionData = monthNames.map((month, index) => {
        const monthIndex = new Date(`${month} 1, 2000`).getMonth();
        return data.annualSubscriptionData[monthIndex] || 0;
      });
      setActualSubscriptionData(alignedSubscriptionData);
      setsubscriptionData(alignedSubscriptionData);
    }

    if (data.annualLeadData) {
      const alignedLeadsData = monthNames.map((month, index) => {
        const monthIndex = new Date(`${month} 1, 2000`).getMonth();
        return data.annualLeadData[monthIndex] || 0;
      });

      setActualLeadsData(alignedLeadsData);
      setleadData(alignedLeadsData);
    }

  }, [data.annualSubscriptionData, data.annualLeadData]);





  const fields = [
    {
      key: "firstName",
      label: "Name",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "email",
      label: "Email",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "leadSource",
      label: "Lead Source",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "attempt",
      label: "Attempted",
      _style: { color: "#6B6B6B", fontWeght: "400", textAlign: "right", fontSize: "0.875rem" },
    },
  ];

  const subscriptionField=[
    {
      key: "organizationName",
      label: " Org Name",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "organizationEmail",
      label: "Org Email ",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "planName",
      label: "Plan Name",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem" },
    },
    {
      key: "endDate",
      label: "End Date",
      _style: { color: "#6B6B6B", fontWeght: "400",textAlign: "right", fontSize: "0.875rem" },
    },
 
  ]


  const Chartdata = {
    labels: xLabels,
    datasets: [
      {
        label: "Subscription",
        data: subscriptionData,
        backgroundColor: '#08D110',
        borderColor: "#08D110",
        pointBorderColor: "#08D110",
        fill: false,
        tension: 0.4,
        shadowColor: '#08D110',
        shadowBlur: 10
      },
      {
        label: "Leads",
        data: leadData,
        backgroundColor: '#2d059f',
        borderColor: "#2d059f",
        pointBorderColor: "#2d059f",

        fill: false,
        tension: 0.4
      }
    ]

  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    plugin: {
      legend: true
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }
    },
  }


  const handleChange = (event) => {
    const monthNames = MonthList.getMonthList();

    if (event.target.value === "6month") {
      const monthsLead = leadData!.slice(6, 12);
      const monthsub = subscriptionData!.slice(6, 12);
      const monthDataSlice = monthNames.slice(6, 12)

      setXLabels(monthDataSlice)
      setLabelData(xLabels.slice(6, 12));
      setsubscriptionData(monthsub);
      setleadData(monthsLead);
    } else if (event.target.value === "12month") {
      setXLabels(monthNames)
      setsubscriptionData(actualSubscriptionData);
      setleadData(actualLeadsData);
    }
  };

  const [subscriptionTypeLoader, setSubscriptionTypeLoader] = useState(false);

  const fetchSubscriptionByExpiryType = async (type: string) => {
    setSubscriptionTypeLoader(true);
    try {
      await axios.get(`${Constants.SUBSCRIPTION_EXPIRY_ORG_URI}/${type}`)
        .then((res) => {
          setSubscriptionExpiryOrg(res.data)
        })
        .catch((error: XMLHttpRequest) => {
          toast.error("Something Went Wrong", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
          
        })
        .finally(() =>
          setSubscriptionTypeLoader(false)
        );
    } catch (err) {

      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });


    }

  }

  const handleSubscriptionChange = (type: string) => {
    setsubscriptionExpiredType(type);
    fetchSubscriptionByExpiryType(type);
  }




  return (
    <div className="dashboard">
      <div className="graph">
        <div className="display-graph">
          <div className="graph-top">
            <div className="lead-box">
              <span className="lead-count">{data.totalLeads}</span>
              <h5 className="lead-text">Total Leads</h5>
            </div>

            <div className="filter">
              <div className="lead-icon">
                <span>
                  <img src={active} alt="" />
                </span>
                <p>Leads</p>
                <span>
                  <img src={Ellipse6} alt="" />
                </span>
                <p>Subscription</p>
              </div>
              <div className="filter-drop">
                <select
                  title="Select"
                  onChange={handleChange}
                  className="filter-input"
                >
                  <option value="">Select</option>
                  <option value="6month">6 Months</option>
                  <option value="12month">12 Months</option>
                </select>
              </div>
            </div>
          </div>

          <div className="graph-bottom">
            <Line options={options} data={Chartdata} />
          </div>
        </div>
        <div className="leads">
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                href="javascript:void(0);"
                active={activeKey === 1}
                onClick={() => setActiveKey(1)}
              >
                Leads
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="javascript:void(0);"
                active={activeKey === 2}
                onClick={() => setActiveKey(2)}
              >
                Subscription
              </CNavLink>
            </CNavItem>

          </CNav>
          <CTabContent>
            {activeKey === 1 ? (
              <>
                <div className="view">
                  <div className="lead">
                    {/* <span className="lead-title">
                      Leads
                    </span> */}
                    <span className="lead-para">
                      Most Attempted within 30 days
                    </span>
                  </div>
                  <div className="view-all">
                    <button onClick={hadleClick}>View All</button>
                    <FaArrowRight />
                  </div>
                </div>
                <div className="lead-table ">
                  <CDataTable
                    columnHeaderSlot={{
                      style: { borderTop: 'none' }
                    }}
                    items={leads}
                    fields={fields}
                    loading={isLoading}
                    hover
                    sorter
                    scopedSlots={{
                      firstName: (item: any) => (
                        <td>
                          <span
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              color: "var(--txt-primary)",
                            }}
                          >
                            {`${item.firstName ? item.firstName : "..."} ${item.lastName ? item.lastName : ""
                              }`}
                          </span>
                        </td>
                      ),
                      email: (item: any) => {
                        return (
                          <td>
                            <span
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                color: " var(--txt-primary)",
                              }}
                            >
                              {item.email ? item.email : ".."}
                            </span>
                          </td>
                        )
                      },
                      leadSource: (item: any) => {
                        return (
                          <td>
                            <span
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                color: " var(--txt-primary)",
                              }}
                            >
                              {item.leadSource ? item.leadSource : ".."}
                            </span>
                          </td>
                        )
                      },
                      attempt: (item: any) => (
                        <td style={{ textAlign: "right", marginRight: "5px" }}>
                          <span
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              color: " var(--txt-primary)",
                            }}
                          >
                            {item.attempt ? item.attempt : ".."}
                          </span>
                        </td>
                      ),
                    }}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            {activeKey === 2 ? (
              <>
                <div className="view">
                  <div className="subscription">
                    <span className="lead-para">
                    The following subscriptions are expiring {subscriptionExpiredType==="week"?"this ":""} {subscriptionExpiredType}.
                    </span>
                    <span className="lead-para">
                      <CNav variant="tabs" role="tablist">
                        <CNavItem>
                          <CNavLink
                            href="javascript:void(0);"
                            active={subscriptionExpiredType === "today"}
                            onClick={() => handleSubscriptionChange("today")}
                          >
                            Today
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            href="javascript:void(0);"
                            active={subscriptionExpiredType === "tomorrow"}
                            onClick={() => handleSubscriptionChange("tomorrow")}
                          >
                            Tomorrow
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            href="javascript:void(0);"
                            active={subscriptionExpiredType === "week"}
                            onClick={() => handleSubscriptionChange("week")}
                          >
                            This week
                          </CNavLink>
                        </CNavItem>

                      </CNav>
                    </span>
                  </div>

                </div>
                <div className="lead-table ">
                  <CDataTable
                    columnHeaderSlot={{
                      style: { borderTop: 'none' }
                    }}
                    items={subscriptionExpiryOrg}
                    fields={subscriptionField}
                    loading={subscriptionTypeLoader}
                    hover
                    sorter
                    scopedSlots={{
                      organizationName: (item: any) => (
                        <td>
                          <span
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              color: "var(--txt-primary)",
                            }}
                          >
                            {`${item.organizationName ? item.organizationName : "..."} `}
                          </span>
                        </td>
                      ),
                      organizationEmail: (item: any) => {
                 
                        return (
                          <td>
                            <span
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                color: " var(--txt-primary)",
                              }}
                            >
                              {item.organizationEmail ? item.organizationEmail : ".."}
                            </span>
                          </td>
                        )
                      },
                      planName: (item: any) => {
                        return (
                          <td>
                            <span
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                color: " var(--txt-primary)",
                              }}
                            >
                              {item.planName? item.planName : ".."}
                            </span>
                          </td>
                        )
                      },
                      endDate: (item: any) => (
                        <td style={{ textAlign: "right", marginRight: "5px" }}>
                          <span
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              color: " var(--txt-primary)",
                            }}
                          >
                            {item.endDate ? moment(item.endDate).format("MM/DD/YYYY") : ".."}
                          </span>
                        </td>
                      ),
                    }}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </CTabContent>

        </div>
      </div>

      <div className="count">
        <div className="firstbox">
          <div className="subscription">

            <div className="display-count">
              <div className="icon">
                <img src={subscription} alt="" />
              </div>
              <div className="detail">
                <span className="count-title">
                  {data.totalSubscriptions}
                </span>
                <span className="sub-para">
                  Subscriptions
                </span>
              </div>
            </div>

            <div className="parent-active">
              <div>
                <img src={active} alt="" />
                <span style={{ paddingLeft: "6px" }}>
                  {data.activeSubscription}
                </span>
                <span className="active">Active</span>
              </div>
              <div>
                <img className=" inactive-icon" src={inactive} alt="" />
                <span style={{ paddingLeft: "6px" }}>
                  {data.inactiveSubscription}
                </span>
                <span className="inactive">Inactive</span>
              </div>
              <div>
                <img className="deleted-icon" src={deleted} alt="" />
                <span style={{ paddingLeft: "6px" }}>
                  {data.deletedSubscription}
                </span>
                <span className="deleted">Deleted</span>
              </div>
            </div>
          </div>

          <div className="user">
            <div className="display-count">
              <div className="icon">
                <img src={user} alt="user" />
              </div>
              <div className="detail">
                <span className="count-title">
                  {data.totalUsers}
                </span>
                <span className="user-para">
                  Users
                </span>
              </div>
            </div>
            <div className="parent-active">
              <div>
                <img src={active} alt="" />
                <span style={{ paddingLeft: "6px" }}>{data.activeUser}</span>
                <span className="active">Active</span>
              </div>
              <div>
                <img className=" inactive-icon" src={inactive} alt="" />
                <span style={{ paddingLeft: "6px" }}>{data.inactiveUser}</span>
                <span className="inactive">Inactive</span>
              </div>
              <div>
                <img className="deleted-icon" src={deleted} alt="" />
                <span style={{ paddingLeft: "6px" }}>{data.deletedUser}</span>
                <span className="deleted">Deleted</span>
              </div>
            </div>
          </div>
        </div>

        <div className="organization">
          <div className="display-count">
            <div className="icon">
              <img src={organization} alt="" />
            </div>
            <div className="detail">
              <span className="count-title">
                {data.totalOrganizations}
              </span>
              <span className="org-para">
                Organizations
              </span>
            </div>
          </div>

          <div className="parent-active">
            <div>
              <img src={active} alt="" />
              <span style={{ paddingLeft: "6px" }}>
                {data.activeOrganization}
              </span>
              <span className="active">Active</span>
            </div>

            <div>
              <img className=" inactive-icon" src={inactive} alt="" />
              <span style={{ paddingLeft: "6px" }}>
                {data.inactiveOrganization}
              </span>
              <span className="inactive">Inactive</span>
            </div>

            <div>
              <img className="deleted-icon" src={deleted} alt="" />
              <span style={{ paddingLeft: "6px" }}>
                {data.deletedOrganization}
              </span>
              <span className="deleted">Deleted</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
