import { CModalBody } from "@coreui/react";
import AvishModal from "components/modal/modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationState } from "store";
import { resetViewPlanModal } from "store/plan/action";

const ViewPlanModal = () => {

  const dispatch: Dispatch<any> = useDispatch();

  const viewPlanModal = useSelector(
    (state: ApplicationState) => state.plan?.viewPlanModal
  );

  function closeHandler() {
    dispatch(resetViewPlanModal());
  }

  const ModalIconBuild = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#linear)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };

  return viewPlanModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="close"
      title="Plan Details"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={closeHandler}
      showModal={!!viewPlanModal?.showModal}
      isFormSubmitting={!!viewPlanModal?.isModalFormSubmitting}
      isSubmitView={false}
    >
      <CModalBody>

        <div className="panel-body">
          <table className="table-latitude">

            <tbody>
              <tr>
                <th>Name</th>
                <td>{viewPlanModal?.plan?.name}</td>

              </tr>
              <tr>
                <th>License Count</th>
                <td>
                  {viewPlanModal?.plan?.minimumLicenceCount != null
                    ? viewPlanModal.plan.minimumLicenceCount < 0
                      ? "Unlimited"
                      : viewPlanModal.plan.minimumLicenceCount
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <th>Allowed No. of Bookmark</th>
                <td>
                  {viewPlanModal?.plan?.allowedNumberOfBookmarks != null
                    ? viewPlanModal.plan.allowedNumberOfBookmarks < 0
                      ? "Unlimited"
                      : viewPlanModal.plan.allowedNumberOfBookmarks
                    : "N/A"}
                  </td>
              </tr>
              <tr>
                <th>Cost Per License</th>
                <td>{viewPlanModal?.plan?.perLicenceCost}</td>

              </tr>
              <tr>
                <th>Skuid:</th>
                <td>{viewPlanModal?.plan?.skuid}</td>

              </tr>
              <tr>
                <th>Subscription Type:</th>
                <td>{viewPlanModal?.plan?.subscriptionType}</td>

              </tr>
              <tr>
                <th>Plan Type</th>
                <td>{viewPlanModal?.plan?.planType}</td>

              </tr>

              <tr>
                <th>Creation Date:</th>
                <td>{moment(viewPlanModal?.plan?.creationDate).format("MMM DD YYYY")}</td>

              </tr>
              <tr>
                <th>Modified Date:</th>
                <td>{moment(viewPlanModal?.plan?.modifiedDate).format("MMM DD YYYY")}</td>

              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <span>Id:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.id}</span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-6">
            <span>Name:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.name}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span>Active:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.isActive ? "Yes" : "No"}</span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-6">
            <span>Deleted:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.isDeleted ? "Yes" : "No"}</span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-6">
            <span>Creation Date:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewPlanModal?.plan?.creationDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span>Modified Date:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewPlanModal?.plan?.modifiedDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span>Skuid:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.skuid}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span>Subscription Type:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.subscriptionType}</span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-6">
            <span>SubscriptionTypeId:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPlanModal?.plan?.subscriptionTypeId}</span>
          </div>
        </div> */}
      </CModalBody>
    </AvishModal>
  ) : (
    <></>
  );
};

export default ViewPlanModal;
