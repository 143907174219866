import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CTooltip,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import AddIconModal from "components/modals/icon/AddIconModal";
import { TotalCountContext } from "context/TotalCount";
import { useContext, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import iconApi from "services/apis/icons.api";
import { ApplicationState } from "store";

const Icons = () => {
  const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const icon = useSelector((state: ApplicationState) => state.icon);
  const loadIcons = async () => iconApi.fetchIcons(dispatch);
  const [allIcons, setAllIcons] = useState(icon.icons);
  const [addIconModal, setAddIconModal] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState("");
  useEffect(() => {
    loadIcons();
  }, []);

  const handleSearchIcons = (val) => {
    let currIcons;
    if (val !== "") {
      currIcons = icon.icons.filter((e) => {
        return e.name.startsWith(val.toLowerCase());
      });
    } else {
      currIcons = icon.icons;
    }
    setAllIcons(currIcons);
  };

  useEffect(() => {
    setAllIcons(icon.icons);
  }, [icon]);

  useEffect(()=>{
    handleSearchIcons(searchKey)
  },[searchKey])



  const IconPageTitleBuild = () => {
    return (
     
        <div className="icon-title">
          <div>
            <div className="page-title">Icons </div>

            <button
              className="icon-create"
              onClick={() => setAddIconModal(!addIconModal)}
            >
              Add New
            </button>
          </div>

          <span>
            <strong>Total Icons : {totalData.totalIcons}</strong>
          </span>
        </div>
   
    );
  };

  return (
    <div>

      <IconPageTitleBuild />

      <div className="tmf-table_wrapper">

        <CCard className="tmf-datatable__wrapper">
          <CCardHeader className="d-flex justify-content-between">

            <span className="search">
              <CButton
                size="sm"
                // onClick={() => handleSearchIcons(searchKey)}
              >
                <div className="d-flex ">
                  <FaSearch style={{ height: "16px", width: "16px" }} />
                </div>
              </CButton>

              <input
                type="text"
                  autoComplete="off"
                placeholder="Search Icon Name"
                onChange={(e) => setSearchKey(e.target.value)}
                value={searchKey}
              />

              {searchKey && (
                <IoMdClose
                  onClick={() => setSearchKey("")}
                  style={{
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }} />
              )}
            </span>

            {/* <CButton
              size="sm"
              style={{
                backgroundColor: "#1C6387",
                color: "white",
                borderRadius: "0px 4px 4px 0px",
              }}
              onClick={() => handleSearchIcons(searchKey)}
            >
              Search
            </CButton> */}


            {/* <CButton
              style={{ backgroundColor: "#1C6387", color: "white" }}
              size="sm"
              onClick={() => setAddIconModal(!addIconModal)}
            >
              Add
            </CButton> */}
          </CCardHeader>
          <CCardBody>
            {icon.isIconsLoading ? (
              <div className="text-center">
                <Loader size="2rem" />
              </div>
            ) : (
              <CRow className="col-lg-12">
                {allIcons.map((icon) => {
                  return (
                    <CTooltip content={icon.name} key={icon.id}>
                      <div
                        className="icon"
                        dangerouslySetInnerHTML={{ __html: icon["value"] }}
                        style={{ cursor: "pointer" }}
                      ></div>
                    </CTooltip>
                  );
                })}
              </CRow>
            )}
          </CCardBody>
        </CCard>


      </div>

      {addIconModal && (
        <AddIconModal
          isOpen={true}
          onClose={false}
          refreshDetails={() => {
            setAddIconModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Icons;
