import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import ViewLicenseDetail from "components/modals/license/ViewLicenseDetail";
import ViewOrganizationDetails from "components/modals/organization/ViewOrganizationDetails";
import { Dispatch, useState } from "react";
import { CiCreditCard1, CiUndo } from "react-icons/ci";
import { FaPrint, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { setDeleteOrganizationModal, setEditOrganizationModal, setUndoDeleteOrganizationModal } from "store/organization/action";

interface IOrganizationActionParams {
  license: IOrganization;
}

const LicenseActions = ({ license }: IOrganizationActionParams) => {
  const [viewLicenseDetails, setViewLicenseDetails] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();

 

  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center", outline: "none", border: "none" }}>
      <button
        onClick={() => {
          setViewLicenseDetails(true);
        }}
        style={{
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          padding: "0px 15px",
          cursor: license.isDeleted ? "not-allowed" : ""
        }}
        disabled={license.isDeleted}
      >
        View
      </button>



      <TmfDropdown>
        {/* {
          license.isDeleted ? (
            <>
            
            </>
       
          ) : (
          
              <TmfDropdownItem
                icon={<GoPencil />}
                text="Edit"
                props={{
                  onClick: () =>console.log("hello")
                    
                }}
              ></TmfDropdownItem>

           
          )
        } */}

      </TmfDropdown>




      {viewLicenseDetails && (
        
        <ViewLicenseDetail
          isOpen={viewLicenseDetails}
          onClose={() => setViewLicenseDetails(false)}
          license={license}
        />
    
      )}
    </div>
  );
};

export default LicenseActions;
