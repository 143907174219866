import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";

import { Dispatch } from "react";
import { CiUndo } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  setEditUserModal,
  setDeleteUserModal,
  setViewUserModal,
  setUndoDeleteUserModal,
} from "store/user/action";

interface IUserActionParams {
  user: IUser;
}

const UserActions = ({ user }: IUserActionParams) => {
  // const [viewUserDetails, setViewUserDetails] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center" }}>
      <button
      disabled={user.isDeleted}
        onClick={() => {
          dispatch(
            setViewUserModal({
              showModal: true,
              isModalFormSubmitting: false,
              user,
            })
          );
        }}


        style={{
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          cursor: user.isDeleted ? "not-allowed" : "pointer",
          padding: "0px 15px"
        }}
      >
        View
      </button>
      <TmfDropdown>
        {user.isDeleted ? (
          <TmfDropdownItem
            icon={<CiUndo/>}
            text="Undo Delete"
            props={{
              onClick: () => {
                dispatch(
                  setUndoDeleteUserModal({
                    showModal: true,
                    isModalFormSubmitting: false,
                    user,
                  })
                );
              },
            }}
          ></TmfDropdownItem>
        ) : (
          <div>
            {/* <TmfDropdownItem
              icon="eye"
              text="View"
              props={{
                onClick: () => {
                  dispatch(
                    setViewUserModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      user,
                    })
                  );
                },
              }}
            ></TmfDropdownItem> */}
            <TmfDropdownItem
             icon={<GoPencil/>}
              text="Edit"
              props={{
                onClick: () => {
                  dispatch(
                    setEditUserModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      user,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>

            <TmfDropdownItem
              icon={<FaRegTrashAlt/>}
              text="Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setDeleteUserModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      user,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
            
            {/* <TmfDropdownItem
            icon="eye"
            text="View Organization"
            // props={{
            //   onClick: () => {
            //     user.organizationID = organization.organizationId
            //   },
            //   to: `/organization`
            // }}
          ></TmfDropdownItem> */}
          </div>
        )}
      </TmfDropdown>
    </div>
  );
};

export default UserActions;
